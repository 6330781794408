'use client';
import React, { useEffect } from 'react';
import { Modal, ModalContent, ModalProps } from '@nextui-org/modal';
import { cn } from '@nextui-org/theme';
import { useSession } from 'next-auth/react';
import { Image } from '@nextui-org/image';
import { motion } from 'framer-motion';

import ArticleAuthor from '../ArticleLayout/ArticleAuthor';
import Tabs from '../Tabs/Tabs';

import ArticleActionsSection from './ArticleActionsSection';

import { heading, paragraph } from '@/theme/typography';
import { TabProps } from '@/app/publish/article/page';
import useTabBarStore from '@/hooks/useBarStore';
import { Slide, UploadGallery } from '@/app/publish/article/_components/ImageGallery/UploadGallery';
import useGalleryShowCaseStore from '@/hooks/useGalleryShowCaseStore';

interface ArticlePreviewModalProps extends Omit<ModalProps, 'children'> {
  title?: string;
  description?: string;
  coverImageUrl?: string;
  // jsonContent?: JSONContent;
  tabs: TabProps[];
  currentTabKey: number;
  setCurrentTabKey: (key: number) => void;
  isModalOpen: boolean;
  onCloseTab: () => void;
  imagesGallery: Slide[];
  layout: 'grid' | 'carousel' | 'thumbnail';
}

const ArticlePreviewModal: React.FC<ArticlePreviewModalProps> = ({
  title,
  description,
  coverImageUrl,
  // jsonContent,
  currentTabKey,
  setCurrentTabKey,
  tabs,
  isModalOpen,
  layout,
  imagesGallery,
  onCloseTab,

  ...others
}) => {
  const { data: session } = useSession();

  const [htmlContent, setHtmlContent] = React.useState<string>();

  useEffect(() => {
    setHtmlContent(tabs.find((tab) => tab.id === Number(currentTabKey))?.htmlContent);
  }, [tabs, currentTabKey]);

  const { isTabBarVisible, toggleTabBar } = useTabBarStore();
  const { isGalleryShowCaseVisible } = useGalleryShowCaseStore();

  return (
    <Modal
      classNames={{
        base: '!m-8 min-h-screen h-auto',
        closeButton: 'top-4 right-4 text-[24px] rounded-none',
      }}
      isOpen={isModalOpen}
      scrollBehavior='outside'
      size='full'
      {...others}
      onClose={onCloseTab}
    >
      <ModalContent className='py-10'>
        <div className={cn('flex w-full flex-col gap-14 pb-14')}>
          <div className={cn('flex w-full flex-col')}>
            <div
              className={cn(
                'flex w-full max-w-4xl flex-col items-start gap-8 self-center pb-8 pt-6',
              )}
            >
              <hgroup className='flex flex-col gap-4'>
                {title && <h1 className={heading({ size: 'h2' })}>{title}</h1>}

                {description && <p className={cn(paragraph(), 'text-gray-600')}>{description}</p>}
              </hgroup>

              {session?.user && (
                <ArticleAuthor
                  author={{
                    name: session.user.name!,
                    username: session.user.username,
                    avatar: session.user.avatar,
                  }}
                  createdAt={new Date()}
                  previewOnly={true}
                />
              )}

              <ArticleActionsSection
                className='w-full'
                commentCount={34}
                previewOnly={true}
                reactionCount={120}
              />
            </div>

            {coverImageUrl && !isGalleryShowCaseVisible && (
              <div className='relative aspect-video max-h-[640px] w-full'>
                <Image
                  removeWrapper
                  alt={title}
                  className='h-full w-full border-2 border-gray-100 bg-white-100 object-cover opacity-100'
                  radius='none'
                  src={coverImageUrl}
                />
              </div>
            )}

            {isGalleryShowCaseVisible && (
              <UploadGallery images={imagesGallery} isPreviewOnly={true} layout={layout} />
            )}

            {!coverImageUrl && (
              <div className='relative aspect-video max-h-[640px] w-full bg-white-100' />
            )}
          </div>

          {isTabBarVisible && (
            <motion.div className='sticky top-0 z-20 flex w-full items-center justify-center border-b-1 border-b-gray-100 bg-neutral-white'>
              <Tabs
                className='w-full max-w-4xl p-0 capitalize'
                classNames={{ tabList: 'p-0' }}
                items={tabs}
                selectedKey={currentTabKey.toString()}
                onSelectionChange={(key) => setCurrentTabKey(key as number)}
              />
            </motion.div>
          )}

          {htmlContent && (
            <div
              dangerouslySetInnerHTML={{
                __html: htmlContent,
              }}
              key={htmlContent}
              className={cn(
                'min-h-[50vh] w-full max-w-4xl self-center',
                '[&_table]:w-full [&_table]:border-collapse [&_table]:border [&_table]:border-gray-200',
                '[&_td]:border [&_td]:border-gray-200 [&_td]:p-2',
                '[&_th]:border [&_th]:border-gray-200 [&_th]:bg-gray-50 [&_th]:p-2 [&_th]:font-medium',
              )}
            />
          )}
          <section className='my-14 flex w-full max-w-4xl flex-col gap-8 self-center'>
            <ArticleActionsSection
              className='w-full'
              commentCount={34}
              previewOnly={true}
              reactionCount={120}
            />
          </section>
        </div>
      </ModalContent>
    </Modal>
  );
};

export default ArticlePreviewModal;
