import { useInfiniteQuery } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';

import { SavedList } from '@/models/saved-list';
import { getMySavedLists } from '@/services/apis/saved-list';

const PAGE_SIZE = 12;

const useMySavedLists = ({ params }: any = {}) => {
  const { data: session } = useSession();

  const { data: savedLists, ...others } = useInfiniteQuery<
    (SavedList & { totalArticles: number })[] | null
  >({
    queryKey: ['saved-lists', session?.user.username, { pageSize: PAGE_SIZE, ...params }],
    queryFn: ({ pageParam }) =>
      getMySavedLists(session?.token!, {
        params: {
          page: pageParam,
          pageSize: PAGE_SIZE,
          ...params,
        },
      }),
    getNextPageParam: (lastPage, allPages) => {
      if (!lastPage?.length) return undefined;
      if (lastPage.length < PAGE_SIZE) return undefined;

      return lastPage.length > 0 ? allPages.length + 1 : undefined;
    },
    staleTime: 10 * 1000,
    initialPageParam: 1,
    enabled: !!session?.token,
  });

  return {
    savedLists,
    ...others,
  };
};

export default useMySavedLists;
