import React from 'react';
import { EmblaOptionsType, EmblaCarouselType } from 'embla-carousel';
import Image from 'next/image';
import { EmblaViewportRefType } from 'embla-carousel-react';
import { cn } from '@nextui-org/theme';

import { Photo } from '@/types/apis/model';
import { Slide } from '@/app/publish/article/_components/ImageGallery/UploadGallery';

type PropType = {
  slides: (Photo | Slide)[];
  options?: EmblaOptionsType;
  emblaRef: EmblaViewportRefType;
  emblaApi: EmblaCarouselType | undefined;
  handleSelectPicture: (id: number) => void;
  currentlySelected: number;
  isGallery?: boolean;
  isPreview?: boolean;
};

const FooterCarousel: React.FC<PropType> = ({
  slides,
  emblaRef,
  // emblaApi,
  handleSelectPicture,
  currentlySelected,
  isGallery = false,
  isPreview = false,
}) => {
  return (
    <section className='relative z-10 mx-auto mt-4 w-full'>
      <div ref={emblaRef} className='overflow-hidden'>
        <div className='-ml-4 flex touch-pan-y'>
          {slides.map((item, index) => (
            <button
              key={item.id}
              aria-label={`Select picture ${item.id}`}
              className='min-w-0 flex-[0_0_50%] pl-4 sm:flex-[0_0_14.28571%]'
              onClick={() => handleSelectPicture(index)}
            >
              <div
                className='relative flex items-center justify-center'
                style={{
                  width: isPreview ? '100%' : isGallery ? '8.6rem' : '11.29569rem',
                  height: isPreview ? '8rem' : isGallery ? '5.06rem' : '6.55431rem',
                }}
              >
                <div
                  className={cn(
                    'absolute left-0 top-0 z-20 h-full w-full border-solid border-[#1781EC] transition-opacity duration-300 ease-in-out',
                    isGallery ? 'border-[1px]' : 'border-[6px]',
                  )}
                  style={{
                    opacity: currentlySelected === index ? 1 : 0,
                  }}
                />
                <Image fill alt='item' className='object-cover' sizes='1920' src={item.image.url} />
              </div>
            </button>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FooterCarousel;
