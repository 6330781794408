import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';

import { getCommentsByArticleId } from '@/services/apis/comment';

const PAGE_SIZE = 10;

const useArticleComments = (articleId: number) => {
  const [currentPage] = useState(1);

  const { data, ...others } = useQuery({
    queryKey: ['article-comments', articleId, { page: currentPage, pageSize: PAGE_SIZE }],
    queryFn: () =>
      getCommentsByArticleId(articleId, {
        'pagination[page]': currentPage,
        'pagination[pageSize]': PAGE_SIZE,
        'pagination[withCount]': true,
      }),
    staleTime: 10 * 1000,
    select: (data) => data,
  });

  return {
    comments: data?.comments,
    ...others,
  };
};

export default useArticleComments;
