'use client';

import { cn } from '@nextui-org/theme';
import { NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import { useCallback, useRef } from 'react';

interface ImageBlockViewProps extends NodeViewProps {}

export const ImageBlockView: React.FC<ImageBlockViewProps> = ({ editor, getPos, node }) => {
  const imageWrapperRef = useRef<HTMLDivElement>(null);
  const { src, align, width } = node.attrs as Node & {
    src: string;
    align: 'left' | 'center' | 'right';
    width: number;
  };

  const onClick = useCallback(() => {
    editor.commands.setNodeSelection(getPos());
  }, [getPos, editor.commands]);

  return (
    <NodeViewWrapper>
      <div
        data-drag-handle
        className={cn('ml-auto mr-auto cursor-auto', {
          'ml-0': align === 'left',
          'mr-0': align === 'right',
          'mx-auto': align === 'center',
        })}
        style={{ width }}
      >
        <div ref={imageWrapperRef} contentEditable={false}>
          <img alt='' role='presentation' src={src} onClick={onClick} onKeyDown={onClick} />
        </div>
      </div>
    </NodeViewWrapper>
  );
};

export default ImageBlockView;
