import { mergeAttributes } from '@tiptap/core';
import TiptapHeading from '@tiptap/extension-heading';
import { cn } from '@nextui-org/theme';

import { heading } from '@/theme/typography';

export const Heading = TiptapHeading.extend({
  levels: [1, 2, 3, 4, 5, 6],

  renderHTML({ node, HTMLAttributes }) {
    const headingClasses = {
      1: heading({ size: 'h1', weight: 'medium' }),
      2: heading({ size: 'h2', weight: 'medium' }),
      3: heading({ size: 'h3', weight: 'medium' }),
      4: heading({ size: 'h4', weight: 'medium' }),
      5: heading({ size: 'h5', weight: 'medium' }),
      6: heading({ size: 'h6', weight: 'medium' }),
    };

    type Level = keyof typeof headingClasses;

    const level = (
      this.options.levels.includes(node.attrs.level) ? node.attrs.level : this.options.levels[0]
    ) as Level;

    return [
      `h${level}`,
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
        class: cn(headingClasses[level], '!m-0 py-3'),
        level,
      }),
      0,
    ];
  },
});

export default Heading;
