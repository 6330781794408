import { Node, ReactNodeViewRenderer } from '@tiptap/react';

import { EmbedEditorView } from './view/EmbedEditorView';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    embedEditor: {
      setEmbedEditor: () => ReturnType;
    };
  }
}

export const EmbedEditor = Node.create({
  name: 'embedEditor',

  isolating: true,

  defining: true,

  group: 'block',

  draggable: true,

  selectable: true,

  inline: false,

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ];
  },

  renderHTML() {
    return ['div', { 'data-type': this.name }];
  },

  addCommands() {
    return {
      setEmbedEditor:
        () =>
        ({ commands }) =>
          commands.insertContent(`<div data-type="${this.name}"></div>`),
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(EmbedEditorView);
  },
});

export default EmbedEditor;
