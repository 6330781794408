'use client';

import {
  BreadcrumbItem,
  BreadcrumbItemProps,
  Breadcrumbs as NextUIBreadcrumbs,
  BreadcrumbsProps as NextUIBreadcrumbsProps,
} from '@nextui-org/breadcrumbs';
import { cn } from '@nextui-org/theme';
import * as React from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownTrigger } from '@nextui-org/dropdown';
import { Button } from '@nextui-org/button';
import { DotsThree } from '@phosphor-icons/react/dist/ssr';
import { Skeleton } from '@nextui-org/skeleton';

import { ChevronRightSvg } from '@/assets/icons';
import { button } from '@/theme/typography';

interface BreadcrumbsProps extends NextUIBreadcrumbsProps {
  items: (Omit<BreadcrumbItemProps, 'children'> & {
    key: string;
    label: string;
  })[];
  isLoading?: boolean;
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ items, isLoading = false, ...others }) => {
  if (isLoading) {
    return (
      <div className='flex items-center space-x-2'>
        {Array.from({ length: 3 }).map((_, index) => (
          <React.Fragment key={index}>
            <Skeleton className='h-6 w-20' />
            {index < 2 && <ChevronRightSvg className='w-5 text-neutral-400' />}
          </React.Fragment>
        ))}
      </div>
    );
  }

  return (
    <NextUIBreadcrumbs
      itemsAfterCollapse={2}
      itemsBeforeCollapse={2}
      maxItems={4}
      renderEllipsis={({ items, separator }) => (
        <div className='flex items-center'>
          <Dropdown>
            <DropdownTrigger>
              <Button
                isIconOnly
                className='hover:bg-white h-6 w-6 min-w-6 text-neutral-white hover:bg-opacity-50'
                size='lg'
                variant='light'
              >
                <DotsThree size={24} />
              </Button>
            </DropdownTrigger>

            <DropdownMenu aria-label='Routes'>
              {items.map((item, index) => (
                <DropdownItem key={index} href={item.href}>
                  {item.children}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
          {separator}
        </div>
      )}
      separator={<ChevronRightSvg className='w-5' />}
      {...others}
    >
      {items.map(({ key, label, href, classNames, ...others }) => (
        <BreadcrumbItem
          key={key}
          classNames={{
            ...classNames,
            item: cn(button(), classNames?.item),
          }}
          href={href}
          {...others}
        >
          {label}
        </BreadcrumbItem>
      ))}
    </NextUIBreadcrumbs>
  );
};

export default Breadcrumbs;
