import { AxiosRequestConfig } from 'axios';

import { publicApi } from '@/config/api';
import { Model } from '@/models/model';
import {
  GetLatestModelsApiResponse,
  GetModelsApiResponse,
  GetModelsInCodenameApiResponse,
  GetMostViewedModelsApiResponse,
  GetMostViewedPhotosApiResponse,
} from '@/types/apis/model';

const SERVICE_PATH = '/models';
const SERVICE_PATH_IN_CODE_NAME = '/codenames';
const SERVICE_PATH_IN_GALLERY_LATEST = '/gallery/latest';
const SERVICE_PATH_IN_GALLERY_MOST_VIEWED = '/gallery/most-viewed';
const SERVICE_PATH_IN_GALLERY_MOST_VIEWED_PHOTOS = '/photos/most-viewed';

export const getModels = async ({ params }: { params?: AxiosRequestConfig['params'] }) => {
  try {
    const { data } = await publicApi<GetModelsApiResponse>({
      method: 'GET',
      url: SERVICE_PATH,
      params,
    });

    const models = data.data.map((item) => ({
      id: item.id,
      name: item.name,

      createdAt: new Date(item.createdAt),
      updatedAt: new Date(item.updatedAt),
      publishedAt: new Date(item.publishedAt),
    })) as Model[];

    return models;
  } catch (error) {
    return null;
  }
};

export const getModelsInCodename = async ({
  id,
  params,
}: {
  id: string;
  params?: AxiosRequestConfig['params'];
}) => {
  try {
    const { data } = await publicApi<GetModelsInCodenameApiResponse>({
      method: 'GET',
      url: `${SERVICE_PATH_IN_CODE_NAME}/${id}`,
      params,
    });

    // console.log(data, 'model data');

    const models = data.models.map((item) => ({
      id: item.id,
      name: item.name,
      description: item.description,
      cover: item.cover,
      slug: item.slug,
      createdAt: new Date(item.createdAt),
      updatedAt: new Date(item.updatedAt),
      publishedAt: new Date(item.publishedAt),
    })) as Model[];

    return { models, meta: data.pagination, codename: data.codename };
  } catch (error) {
    return null;
  }
};

export const getLatestModels = async ({ params }: { params?: AxiosRequestConfig['params'] }) => {
  try {
    const { data: res } = await publicApi<GetLatestModelsApiResponse>({
      method: 'GET',
      url: SERVICE_PATH_IN_GALLERY_LATEST,
      params,
    });

    // console.log(res, 'res check');

    return { albums: res.albums, meta: res.pagination };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('`getLatestModels` API Error', error);

    return null;
  }
};

export const getMostViewedModels = async ({
  params,
}: {
  params?: AxiosRequestConfig['params'];
}) => {
  try {
    const { data: res } = await publicApi<GetMostViewedModelsApiResponse>({
      method: 'GET',
      url: SERVICE_PATH_IN_GALLERY_MOST_VIEWED,
      params,
    });

    // console.log(res, 'res check');

    return { albums: res.paginatedAlbums, meta: res.pagination };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('`getMostViewedModels` API Error', error);

    return null;
  }
};

export const getMostViewedPhotos = async ({
  params,
}: {
  params?: AxiosRequestConfig['params'];
}) => {
  try {
    const { data: res } = await publicApi<GetMostViewedPhotosApiResponse>({
      method: 'GET',
      url: SERVICE_PATH_IN_GALLERY_MOST_VIEWED_PHOTOS,
      params,
    });

    // console.log(res, 'res check');

    return { photos: res.paginatedPhotos, meta: res.pagination };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('`getMostViewedPhotos` API Error', error);

    return null;
  }
};
