'use client';

import { Button } from '@nextui-org/button';
import { Divider } from '@nextui-org/divider';
import { cn } from '@nextui-org/theme';
import { Highlighter } from '@phosphor-icons/react';
import Link from '@tiptap/extension-link';
import { EditorState } from '@tiptap/pm/state';
import { EditorView } from '@tiptap/pm/view';
import { BubbleMenu, Editor, isTextSelection } from '@tiptap/react';
import React from 'react';
import Youtube from '@tiptap/extension-youtube';

import { EmbedEditor } from '../../extensions/EmbedEditor';
import { HorizontalRule } from '../../extensions/HorizontalRule';
import { ImageBlock } from '../../extensions/ImageBlock';
import { ImageUpload } from '../../extensions/ImageUpload';

import BlocksDropdown from './BlocksDropdown';
import { TOOL_GROUPS } from './config';
import { EditLinkPopover } from './EditLinkPopover';

export const isTableGripSelected = (node: HTMLElement) => {
  let container = node;

  while (container && !['TD', 'TH'].includes(container.tagName)) {
    container = container.parentElement!;
  }

  const gripColumn =
    container && container.querySelector && container.querySelector('a.grip-column.selected');
  const gripRow =
    container && container.querySelector && container.querySelector('a.grip-row.selected');

  if (gripColumn || gripRow) {
    return true;
  }

  return false;
};

export const isCustomNodeSelected = (editor: Editor, node: HTMLElement) => {
  const customNodes = [
    EmbedEditor.name,
    HorizontalRule.name,
    ImageBlock.name,
    ImageUpload.name,
    Link.name,
    Youtube.name,
  ];

  return customNodes.some((type) => editor.isActive(type)) || isTableGripSelected(node);
};

interface TextMenuProps {
  editor: Editor;
}

export interface ShouldShowProps {
  editor?: Editor;
  view: EditorView;
  state?: EditorState;
  oldState?: EditorState;
  from?: number;
  to?: number;
}

export const isTextSelected = ({ editor }: { editor: Editor }) => {
  const {
    state: {
      doc,
      selection,
      selection: { empty, from, to },
    },
  } = editor;

  // Sometime check for `empty` is not enough.
  // Double-click an empty paragraph returns a node size of 2.
  // So we check also for an empty text size.
  const isEmptyTextBlock = !doc.textBetween(from, to).length && isTextSelection(selection);

  if (empty || isEmptyTextBlock || !editor.isEditable) {
    return false;
  }

  return true;
};

const TextMenu: React.FC<TextMenuProps> = ({ editor }) => {
  const containerRef = React.useRef<HTMLDivElement>(null);

  const shouldShow = React.useCallback(
    ({ view, from }: ShouldShowProps) => {
      if (!view || editor?.view.dragging) {
        return false;
      }

      const domAtPos = view.domAtPos(from || 0).node as HTMLElement;
      const nodeDOM = view.nodeDOM(from || 0) as HTMLElement;
      const node = nodeDOM || domAtPos;

      if (isCustomNodeSelected(editor, node)) {
        return false;
      }

      return isTextSelected({ editor });
    },
    [editor],
  );

  if (!editor) return null;

  return (
    <BubbleMenu
      className='border-1 border-gray-100 bg-background'
      editor={editor}
      pluginKey='textMenu'
      shouldShow={shouldShow}
      tippyOptions={{
        popperOptions: { modifiers: [{ name: 'eventListeners', options: { scroll: true } }] },
        duration: 100,
        animation: 'scale-subtle',
        maxWidth: '100vw',
        zIndex: 40,
      }}
      updateDelay={100}
    >
      <div ref={containerRef} className='relative z-10 flex shrink-0 flex-row items-center gap-1'>
        <BlocksDropdown editor={editor} />

        <Divider className='h-8' orientation='vertical' />

        {TOOL_GROUPS.map(({ type, commands }, index) => (
          <React.Fragment key={type}>
            <div className='flex shrink-0 flex-row items-center'>
              {commands.map(({ name, icon: Icon, isActive, action }) => (
                <Button
                  key={name}
                  isIconOnly
                  radius='none'
                  variant='light'
                  onClick={() => action?.(editor)}
                >
                  <Icon
                    className={cn('pointer-events-none h-5 w-5 text-gray-800', {
                      'text-primary': isActive(editor),
                    })}
                  />
                </Button>
              ))}
            </div>

            {index !== TOOL_GROUPS.length - 1 && <Divider className='h-8' orientation='vertical' />}
          </React.Fragment>
        ))}

        <Divider className='h-8' orientation='vertical' />

        <div className='flex shrink-0 flex-row items-center'>
          <EditLinkPopover
            onSetLink={(url) =>
              editor.chain().focus().setLink({ href: url, target: '_blank' }).run()
            }
          />
          <Button
            isIconOnly
            radius='none'
            variant='light'
            onClick={() => editor.chain().focus().toggleHighlight().run()}
          >
            <Highlighter
              className={cn('pointer-events-none h-5 w-5 text-gray-800', {
                'text-primary': editor.isActive('highlight'),
              })}
            />
          </Button>
        </div>
      </div>
    </BubbleMenu>
  );
};

export default TextMenu;
