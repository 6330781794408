'use client';

import { Input, InputProps } from '@nextui-org/input';
import { cn } from '@nextui-org/theme';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

interface IRHFFileProps extends InputProps {
  name: string;
}

const RHFFile: React.FC<IRHFFileProps> = ({ name, disabled, className, onChange, ...others }) => {
  const { control } = useFormContext<Record<string, FileList>>();

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { value, onChange: controllerOnChange, ...field },
        formState: { disabled: formDisabled },
      }) => (
        <Input
          aria-hidden
          hidden
          accept='*'
          {...field}
          {...others}
          className={cn('hidden', className)}
          disabled={disabled || formDisabled}
          id={name}
          type='file'
          value={value?.[0]?.name}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            controllerOnChange(event.target.files?.[0]);
            onChange?.(event);
          }}
        />
      )}
    />
  );
};

export default RHFFile;
