import { Image, ImageProps } from '@nextui-org/image';
import { cn } from '@nextui-org/theme';
import React from 'react';

import MediaThumbnailAction from './MediaThumbnailAction';

interface MediaThumbnailProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'id'> {
  id: number;
  imageUrl: string;
  imageProps?: Omit<ImageProps, 'src' | 'className'>;
  classNames?: {
    image?: string;
  };
}

const MediaThumbnail: React.FC<MediaThumbnailProps> = ({
  id,
  imageUrl,
  imageProps,
  className,
  classNames,
  ...others
}) => {
  return (
    <div className={cn('group relative overflow-hidden', className)} {...others}>
      <Image
        className={cn(
          'relative h-full w-full bg-gray-100 object-cover',
          'transition-all group-hover:scale-105',
          classNames?.image,
        )}
        loading='lazy'
        src={imageUrl}
        {...imageProps}
      />

      <div className='absolute right-0 top-0 z-10 h-full w-full transition-all group-hover:bg-black/20' />

      <MediaThumbnailAction id={id} imageUrl={imageUrl} />
    </div>
  );
};

export default MediaThumbnail;
