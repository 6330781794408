import { Button } from '@nextui-org/button';
import {
  Dropdown,
  DropdownItem,
  DropdownItemProps,
  DropdownMenu,
  DropdownTrigger,
} from '@nextui-org/dropdown';
import { Copy, DotsSixVertical, Plus, TextTSlash, Trash } from '@phosphor-icons/react/dist/ssr';
import TipTapDragHandle from '@tiptap-pro/extension-drag-handle-react';
import { Node } from '@tiptap/pm/model';
import { NodeSelection } from '@tiptap/pm/state';
import { Editor } from '@tiptap/react';
import React, { useCallback, useState } from 'react';

export interface DragHandleProps {
  editor: Editor;
}

export const DragHandle: React.FC<DragHandleProps> = ({ editor }) => {
  const [currentNode, setCurrentNode] = useState<Node | null>(null);
  const [currentNodePos, setCurrentNodePos] = useState<number>(-1);

  const handleNodeChange = useCallback(
    (data: { node: Node | null; editor: Editor; pos: number }) => {
      if (data.node) setCurrentNode(data.node);

      setCurrentNodePos(data.pos);
    },
    [setCurrentNodePos, setCurrentNode],
  );

  const [menuOpen, setMenuOpen] = useState(false);

  const resetTextFormatting = useCallback(() => {
    const chain = editor.chain();

    chain.setNodeSelection(currentNodePos).unsetAllMarks();

    if (currentNode?.type.name !== 'paragraph') {
      chain.setParagraph();
    }

    chain.run();
  }, [editor, currentNodePos, currentNode?.type.name]);

  const duplicateNode = useCallback(() => {
    editor.commands.setNodeSelection(currentNodePos);

    const { $anchor } = editor.state.selection;
    const selectedNode = $anchor.node(1) || (editor.state.selection as NodeSelection).node;

    editor
      .chain()
      .setMeta('hideDragHandle', true)
      .insertContentAt(currentNodePos + (currentNode?.nodeSize || 0), selectedNode.toJSON())
      .run();
  }, [editor, currentNodePos, currentNode?.nodeSize]);

  const copyNodeToClipboard = useCallback(() => {
    editor.chain().setMeta('hideDragHandle', true).setNodeSelection(currentNodePos).run();

    document.execCommand('copy');
  }, [editor, currentNodePos]);

  const deleteNode = useCallback(() => {
    editor
      .chain()
      .setMeta('hideDragHandle', true)
      .setNodeSelection(currentNodePos)
      .deleteSelection()
      .run();
  }, [editor, currentNodePos]);

  const handleAdd = useCallback(() => {
    if (currentNodePos !== -1) {
      const currentNodeSize = currentNode?.nodeSize || 0;
      const insertPos = currentNodePos + currentNodeSize;
      const currentNodeIsEmptyParagraph =
        currentNode?.type.name === 'paragraph' && currentNode?.content?.size === 0;
      const focusPos = currentNodeIsEmptyParagraph ? currentNodePos + 2 : insertPos + 2;

      editor
        .chain()
        .command(({ dispatch, tr, state }) => {
          if (dispatch) {
            if (currentNodeIsEmptyParagraph) {
              tr.insertText('/', currentNodePos, currentNodePos + 1);
            } else {
              tr.insert(
                insertPos,
                state.schema.nodes.paragraph.create(null, [state.schema.text('/')]),
              );
            }

            return dispatch(tr);
          }

          return true;
        })
        .focus(focusPos)
        .run();
    }
  }, [currentNode, currentNodePos, editor]);

  React.useEffect(() => {
    if (menuOpen) {
      editor.commands.setMeta('lockDragHandle', true);
    } else {
      editor.commands.setMeta('lockDragHandle', false);
    }
  }, [editor, menuOpen]);

  return (
    <TipTapDragHandle
      className='-mt-1'
      editor={editor}
      pluginKey='dragHandle'
      tippyOptions={{
        offset: [-2, 16],
        zIndex: 99,
      }}
      onNodeChange={handleNodeChange}
    >
      <div className='flex items-center'>
        <Button
          draggable
          isIconOnly
          radius='none'
          tabIndex={-1}
          variant='light'
          onClick={handleAdd}
        >
          <Plus size={20} />
        </Button>

        <Dropdown isOpen={menuOpen} radius='none' onOpenChange={setMenuOpen}>
          <DropdownTrigger as='div'>
            <Button
              draggable
              isIconOnly
              className='cursor-grab'
              radius='none'
              tabIndex={-1}
              variant='light'
            >
              <DotsSixVertical size={20} />
            </Button>
          </DropdownTrigger>

          <DropdownMenu
            items={
              [
                {
                  key: 'resetTextFormatting',
                  content: 'Clear formatting',
                  onClick: resetTextFormatting,
                  startContent: <TextTSlash size={20} />,
                },
                {
                  key: 'copyNodeToClipboard',
                  content: 'Copy to clipboard',
                  onClick: copyNodeToClipboard,
                  startContent: <Copy size={20} />,
                },
                {
                  key: 'duplicateNode',
                  content: 'Duplicate',
                  onClick: duplicateNode,
                  startContent: <Copy size={20} />,
                },
                {
                  key: 'deleteNode',
                  content: 'Delete',
                  onClick: deleteNode,
                  startContent: <Trash size={20} />,
                  color: 'danger',
                },
              ] as DropdownItemProps[]
            }
          >
            {({ key, content, ...others }) => (
              <DropdownItem key={key} className='rounded-none' {...others}>
                {content}
              </DropdownItem>
            )}
          </DropdownMenu>
        </Dropdown>
      </div>
    </TipTapDragHandle>
  );
};

export default DragHandle;
