import * as React from 'react';
import { CalendarBlank } from '@phosphor-icons/react/dist/ssr';
import { format } from 'date-fns';

import { Thumbnail } from '..';

import ArticleThumbnailAuthor from './ArticleThumbnailAuthor';
import ArticleBadge from './ArticleBadge';

import { Article } from '@/models/article';

interface ArticleThumbnailProps extends Partial<React.ComponentProps<typeof Thumbnail>> {
  article: Article;
  imageSize?: 'original' | 'large' | 'medium' | 'small' | 'thumbnail';
  href?: string;
}

const ArticleThumbnail: React.FC<ArticleThumbnailProps> = ({
  article,
  imageSize = 'medium',
  href,
  ...others
}) => {
  return (
    <Thumbnail
      {...others}
      href={href ?? `/${article.type?.slug}/${article.slug}`}
      imageUrl={
        (imageSize === 'original'
          ? article.cover?.url
          : (article.cover?.formats?.[imageSize]?.url ?? '')) ?? ''
      }
      metadata={[
        {
          key: 'createdBy',
          value: article.author ? <ArticleThumbnailAuthor author={article.author} /> : null,
        },
        {
          key: 'createdAt',
          value: format(new Date(article.createdAt), 'd MMM yyyy').toUpperCase(),
          icon: <CalendarBlank className='w-5' />,
        },
      ]}
      rightBadge={<ArticleBadge badge={article.badge} />}
      tags={article.categories?.map(({ id, name }) => ({ id, label: name }))}
      title={article.title}
    />
  );
};

export default ArticleThumbnail;
