import { AxiosRequestConfig } from 'axios';

import { publicApi } from '@/config/api';
import { GetDetailModeleApiResponse, Photo } from '@/types/apis/model';

const SERVICE_PATH = '/models';

export const getDetailModel = async ({
  id,
  params,
}: {
  id: string;
  params?: AxiosRequestConfig['params'];
}) => {
  try {
    const { data } = await publicApi<GetDetailModeleApiResponse>({
      method: 'GET',
      url: `${SERVICE_PATH}/${id}`,
      params,
    });

    const modelsDetail = data.album.photos.map((item) => ({
      id: item.id,
      createdAt: item.createdAt,
      updatedAt: item.updatedAt,
      image: item.image,
      user: item.user,
    })) as Photo[];

    const model = data.model;

    return { modelsDetail, model, meta: data.album.pagination };
  } catch (error) {
    return null;
  }
};
