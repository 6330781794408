'use client';

import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Input, InputProps } from '@nextui-org/input';
import { cn } from '@nextui-org/theme';

import { body } from '@/theme/typography';

interface RHFInputProps extends InputProps {
  name: string;
}

const RHFInput: React.FC<RHFInputProps> = ({ name, classNames, ...others }) => {
  const { control } = useFormContext<Record<string, string>>();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error }, formState: { disabled } }) => (
        <Input
          labelPlacement='outside'
          radius='none'
          variant='bordered'
          {...field}
          {...others}
          classNames={{
            ...classNames,
            inputWrapper: cn('shadow-none', classNames?.inputWrapper),
            label: cn(body({ weight: 'medium' }), classNames?.label),
          }}
          disabled={disabled}
          errorMessage={error?.message}
          isInvalid={!!error}
        />
      )}
    />
  );
};

export default RHFInput;
