import * as React from 'react';

import { CarReviewBadge, ReportReviewBadge } from '..';

import { Article } from '@/models/article';

interface ArticleBadgeProps {
  badge: Article['badge'];
  className?: string;
}

const ArticleBadge: React.FC<ArticleBadgeProps> = ({ badge, className }) => {
  if (!badge) return null;

  switch (badge?.type.toLocaleLowerCase()) {
    case 'car review':
      return (
        <CarReviewBadge
          className={className}
          score={isNaN(Number(badge.value)) ? 0 : Number(badge.value)}
        />
      );
    case 'long-term review':
      return <ReportReviewBadge className={className} label={badge.value} />;
    default:
      return null;
  }
};

export default ArticleBadge;
