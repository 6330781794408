import { AxiosRequestConfig } from 'axios';

import { publicApi } from '@/config/api';
import { Series, SeriesDetail } from '@/models/series';
import { GetSeriesApiResponse, GetSeriesBySlugApiResponse } from '@/types/apis/series';
import { PaginationType } from '@/types/apis';

const SERVICE_PATH = '/car-series';

export const getSeries = async ({ params }: { params?: AxiosRequestConfig['params'] }) => {
  try {
    const { data } = await publicApi<GetSeriesApiResponse>({
      method: 'GET',
      url: SERVICE_PATH,
      params,
    });

    const series = data.results.map((item) => ({
      id: item.id,
      name: item.name,
      slug: item.slug,
      cover: item.cover,
      createdAt: new Date(item.createdAt),
      updatedAt: new Date(item.updatedAt),
      publishedAt: new Date(item.publishedAt),
    })) as Series[];

    const result = { series, meta: data.pagination } as { series: Series[]; meta: PaginationType };

    return result;
  } catch (error) {
    return null;
  }
};

export const getSeriesBySlug = async (slugOrId: string | number) => {
  try {
    const { data: res } = await publicApi<GetSeriesBySlugApiResponse>({
      method: 'GET',
      url: `${SERVICE_PATH}/${slugOrId}`,
    });

    const { results: querySeries } = res;

    const series = {
      ...querySeries,

      ...(querySeries
        ? {
            type: {
              ...querySeries,
              // slug: ARTICLE_SLUGS.find((slug) => slug.name === querySeries.type.name)?.slug ?? null,
              slug: querySeries.name,
              createdAt: new Date(querySeries.createdAt),
              updatedAt: new Date(querySeries.updatedAt),
              publishedAt: new Date(querySeries.publishedAt),
            },
          }
        : {}),

      ...(querySeries.cover
        ? {
            cover: {
              ...querySeries.cover,
              createdAt: new Date(querySeries.createdAt),
              updatedAt: new Date(querySeries.updatedAt),
            },
          }
        : {}),

      ...(querySeries.collection
        ? {
            collection: {
              ...querySeries.collection,
              createdAt: new Date(querySeries.collection.createdAt),
              updatedAt: new Date(querySeries.collection.updatedAt),
              publishedAt: new Date(querySeries.collection.publishedAt),

              reports: querySeries.collection.reports.map((report: any) => ({
                ...report,
                createdAt: new Date(report.createdAt),
              })),
            },
          }
        : {}),
    } as SeriesDetail;

    // console.log(series, 'series api');

    return series;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('`getArticleBySlug` API Error', error);

    return null;
  }
};
