import { cn } from '@nextui-org/theme';

import { ArticleThumbnail, SectionTitle } from '@/components';
import { heading } from '@/theme/typography';
import { Article } from '@/models/article';

interface ArticleAsideProps {
  categories: {
    title: string;
    articles: Article[];
  }[];
  className?: string;
}

const ArticleAside: React.FC<ArticleAsideProps> = ({ categories, className }) => (
  <aside className={cn('xl:w-1/3', className)}>
    <div className='flex flex-col gap-8'>
      {categories.map(
        ({ title, articles }) =>
          (articles?.length ?? 0) > 0 && (
            <section key={title} className='flex flex-col gap-6'>
              <SectionTitle
                classNames={{ title: heading({ size: 'h4' }) }}
                notation='dot'
                title={title}
              />

              <ul className='grid grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-3 xl:grid-cols-1'>
                {articles?.map((article) => (
                  <li key={article.id}>
                    <ArticleThumbnail
                      article={article}
                      className='flex-col xl:flex-row'
                      classNames={{
                        title: cn(heading({ size: 'h6', weight: 'bold' }), 'line-clamp-2'),
                        imageWrapper: 'shrink-0 h-32 md:h-auto xl:h-20',
                        metatada: 'text-gray-300',
                        description: 'text-gray-300',
                      }}
                    />
                  </li>
                ))}
              </ul>
            </section>
          ),
      )}
    </div>
  </aside>
);

export default ArticleAside;
