import { useQuery } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';

import { getSavedListById } from '@/services/apis/saved-list';

const useSavedList = (id: number, { params }: any = {}) => {
  const { data: session } = useSession();

  const { data, ...others } = useQuery({
    queryKey: ['saved-list', id, params],
    queryFn: () => getSavedListById(id, session?.token!, { params }),
    staleTime: 10 * 1000,
    select: (data) => data,
    enabled: !!session?.token,
  });

  return {
    savedList: data,
    ...others,
  };
};

export default useSavedList;
