'use client';

import { Button } from '@nextui-org/button';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalProps,
} from '@nextui-org/modal';
import { cn } from '@nextui-org/theme';
import React, { useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useRouter } from 'next/navigation';

import { heading } from '@/theme/typography';
import { RHFInput } from '@/components';
import { useAlbumMutation } from '@/hooks/mutations';
import { useAlbum } from '@/hooks/queries';

interface UpdateAlbumModalProps extends Omit<ModalProps, 'children' | 'id'> {
  id: number;
}

interface UpdateAlbumFormAttributes {
  title: string;
}

const UpdateAlbumModal: React.FC<UpdateAlbumModalProps> = ({ id, classNames, ...others }) => {
  const router = useRouter();

  const { album, isLoading } = useAlbum(id);
  const { updateMutation } = useAlbumMutation();

  const method = useForm<UpdateAlbumFormAttributes>({
    disabled: isLoading || updateMutation.isPending,
  });

  useEffect(() => {
    if (album) {
      method.reset({
        title: album.name,
      });
    }
  }, [album]);

  const handleSubmit: SubmitHandler<UpdateAlbumFormAttributes> = async ({ title }) => {
    await updateMutation.mutateAsync(
      { id, data: { name: title } },
      {
        onSuccess: () => {
          toast.success('Album updated successfully');
          method.reset();
          router.refresh();
        },
      },
    );
  };

  return (
    <Modal
      classNames={{
        base: 'max-w-lg',
        closeButton: 'top-4 right-4 text-[24px] rounded-none',
        ...classNames,
      }}
      radius='none'
      {...others}
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader
              className={cn(
                heading({ size: 'h6', weight: 'medium' }),
                'self-center p-6 text-center',
              )}
            >
              Rename album
            </ModalHeader>

            <ModalBody className='gap-8'>
              <FormProvider {...method}>
                <RHFInput
                  required
                  classNames={{ inputWrapper: 'shadow-none' }}
                  label='Name'
                  labelPlacement='outside'
                  name='title'
                  placeholder='Give it a name'
                  radius='none'
                  size='lg'
                  variant='bordered'
                />
              </FormProvider>
            </ModalBody>

            <ModalFooter className='gap-4 p-6'>
              <Button
                disabled={updateMutation.isPending}
                radius='none'
                size='lg'
                variant='bordered'
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                color='primary'
                isLoading={updateMutation.isPending}
                radius='none'
                size='lg'
                onClick={async () => {
                  await method.handleSubmit(handleSubmit)();
                  onClose();
                }}
              >
                Save
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default UpdateAlbumModal;
