'use client';

import React from 'react';
import { BubbleMenu, Editor } from '@tiptap/react';
import { v4 as uuid } from 'uuid';
import { Instance, sticky } from 'tippy.js';
import { Button } from '@nextui-org/button';
import { cn } from '@nextui-org/theme';
import { FrameCorners, Rectangle } from '@phosphor-icons/react/dist/ssr';

import { getRenderContainer } from '../../utils';

interface ImageMenuProps {
  editor: Editor;
  appendTo?: React.RefObject<any>;
}

const ImageMenu: React.FC<ImageMenuProps> = ({ editor, appendTo }) => {
  const tippyInstance = React.useRef<Instance | null>(null);

  const getReferenceClientRect = React.useCallback(() => {
    const renderContainer = getRenderContainer(editor, 'node-imageBlock');
    const rect = renderContainer?.getBoundingClientRect() || new DOMRect(-1000, -1000, 0, 0);

    return rect;
  }, [editor]);

  const shouldShow = React.useCallback(() => editor.isActive('imageBlock'), [editor]);

  if (!editor) return null;

  return (
    <BubbleMenu
      className='border-1 border-gray-100 bg-background'
      editor={editor}
      pluginKey={`imageBlockMenu-${uuid()}`}
      shouldShow={shouldShow}
      tippyOptions={{
        offset: [0, 8],
        popperOptions: {
          modifiers: [{ name: 'flip', enabled: false }],
        },
        zIndex: 40,
        getReferenceClientRect,
        onCreate: (instance: Instance) => {
          tippyInstance.current = instance;
        },
        appendTo: () => {
          return appendTo?.current;
        },
        plugins: [sticky],
        sticky: 'popper',
        duration: 100,
      }}
      updateDelay={100}
    >
      <div className='relative z-50 flex shrink-0 flex-row items-center gap-1'>
        {[
          { type: 'fit', icon: Rectangle },
          { type: 'full-width', icon: FrameCorners },
        ].map(({ type, icon: Icon }) => (
          <Button
            key={type}
            isIconOnly
            radius='none'
            variant='light'
            // onClick={() => command?.(editor)}
          >
            <Icon
              className={cn('pointer-events-none h-5 w-5 text-gray-800', {
                'text-primary': editor.isActive({ textAlign: type }),
              })}
            />
          </Button>
        ))}
      </div>
    </BubbleMenu>
  );
};

export default ImageMenu;
