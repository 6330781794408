import * as Yup from 'yup';

export const EditUserProfileSchema = Yup.object({
  name: Yup.string()
    .required('Name is required')
    .max(50, 'Name must be at most 50 characters long'),
  username: Yup.string().required('Username is required'),
  bio: Yup.string().nullable().optional().max(160, 'Short bio must be at most 150 characters long'),
  links: Yup.array().of(
    Yup.object({
      type: Yup.string().required('Name is required'),
      url: Yup.string()
        .required('Select a type')
        .test('is-url', 'Invalid URL', (value) => {
          try {
            new URL(value);

            return true;
          } catch (error) {
            return false;
          }
        }),
    }),
  ),
});
