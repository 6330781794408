import { BubbleMenu as BaseBubbleMenu } from '@tiptap/react';
import React, { useCallback } from 'react';
import { Button } from '@nextui-org/button';
import { Card, CardBody } from '@nextui-org/card';
import { ColumnsPlusLeft, ColumnsPlusRight, TrashSimple } from '@phosphor-icons/react';

import { isColumnGripSelected } from './utils';

import { MenuProps, ShouldShowProps } from '@/types/editor/type';

export const TableColumnMenu = React.memo(({ editor, appendTo }: MenuProps): JSX.Element => {
  const shouldShow = useCallback(
    ({ view, state, from }: ShouldShowProps) => {
      if (!state) {
        return false;
      }

      return isColumnGripSelected({ editor, view, state, from: from || 0 });
    },
    [editor],
  );

  const onAddColumnBefore = useCallback(() => {
    editor.chain().focus().addColumnBefore().run();
  }, [editor]);

  const onAddColumnAfter = useCallback(() => {
    editor.chain().focus().addColumnAfter().run();
  }, [editor]);

  const onDeleteColumn = useCallback(() => {
    editor.chain().focus().deleteColumn().run();
  }, [editor]);

  return (
    <BaseBubbleMenu
      editor={editor}
      pluginKey='tableColumnMenu'
      shouldShow={shouldShow}
      tippyOptions={{
        appendTo: () => {
          return appendTo?.current;
        },
        offset: [0, 15],
        popperOptions: {
          modifiers: [{ name: 'flip', enabled: false }],
        },
        zIndex: 100,
      }}
      updateDelay={0}
    >
      <Card className='w-48 bg-white-50 shadow-md' radius='none'>
        <CardBody className='p-2'>
          <Button
            fullWidth
            className='mb-2 justify-start'
            radius='none'
            startContent={<ColumnsPlusLeft size={18} />}
            variant='light'
            onClick={onAddColumnBefore}
          >
            Add column before
          </Button>
          <Button
            fullWidth
            className='mb-2 justify-start'
            radius='none'
            startContent={<ColumnsPlusRight size={18} />}
            variant='light'
            onClick={onAddColumnAfter}
          >
            Add column after
          </Button>
          <Button
            fullWidth
            className='justify-start text-danger'
            radius='none'
            startContent={<TrashSimple size={18} />}
            variant='light'
            onClick={onDeleteColumn}
          >
            Delete column
          </Button>
        </CardBody>
      </Card>
    </BaseBubbleMenu>
  );
});

TableColumnMenu.displayName = 'TableColumnMenu';

export default TableColumnMenu;
