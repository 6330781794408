import { useInfiniteQuery } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';

import { getMyAlbums } from '@/services/apis/album';
import { Album } from '@/models/album';

const PAGE_SIZE = 12;

const useMyAlbums = ({ params }: any = {}) => {
  const { data: session } = useSession();

  const { data: albums, ...others } = useInfiniteQuery<Album[] | null>({
    queryKey: ['albums', session?.user.username, { pageSize: PAGE_SIZE, ...params }],
    queryFn: ({ pageParam }) =>
      getMyAlbums(session?.token!, {
        params: {
          page: pageParam,
          pageSize: PAGE_SIZE,
          ...params,
        },
      }),
    getNextPageParam: (lastPage, allPages) => {
      if (!lastPage?.length) return undefined;
      if (lastPage.length < PAGE_SIZE) return undefined;

      return lastPage.length > 0 ? allPages.length + 1 : undefined;
    },
    staleTime: 10 * 1000,
    initialPageParam: 1,
    enabled: !!session?.token,
  });

  return {
    albums,
    ...others,
  };
};

export default useMyAlbums;
