import { mergeAttributes } from '@tiptap/core';
import TiptapHorizontalRule from '@tiptap/extension-horizontal-rule';

export const HorizontalRule = TiptapHorizontalRule.extend({
  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
        'data-type': this.name,
      }),
      ['hr'],
    ];
  },
}).configure({
  HTMLAttributes: {
    class: 'my-6 cursor-auto py-2 transition-all duration-100 ease-in-out mx-auto max-w-[19.6rem]',
  },
});

export default HorizontalRule;
