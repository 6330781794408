import { Modal, ModalContent, ModalBody, useDisclosure } from '@nextui-org/modal';
import { cn } from '@nextui-org/theme';
import { SetStateAction, Dispatch, useState, useEffect, useRef } from 'react';
import { Button } from '@nextui-org/button';
import { Hash, UploadSimple, X } from '@phosphor-icons/react';

import { Slide } from './UploadGallery';
import { GalleryCodeModal } from './GalleryCodeModal';

import { body, heading } from '@/theme/typography';
import { Photo } from '@/types/apis/model';

interface EditGalleryModalProps {
  isOpen: boolean;
  onClose: () => void;
  setImages: Dispatch<SetStateAction<Slide[]>>;
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>, isEdit: boolean) => void;
  imageStore: Slide[];
  setImageStore: Dispatch<SetStateAction<Slide[]>>;
}

export function EditGalleryModal({
  isOpen,
  onClose,
  setImages,
  handleFileChange,
  imageStore,
  setImageStore,
}: EditGalleryModalProps) {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const {
    isOpen: isGalleryCodeModalOpen,
    onOpen: onGalleryCodeModalOpen,
    onClose: onGalleryCodeModalClose,
  } = useDisclosure();

  const [confirmedPhotos, setConfirmedPhotos] = useState<Photo[]>([]);

  const handleRemoveImage = (id: string) => {
    setImageStore((prev) => prev.filter((image) => image.id !== id));
  };

  const handleDone = () => {
    setImages(imageStore);
    onClose();
    // setImageStore([]);
  };

  useEffect(() => {
    if (confirmedPhotos.length > 0) {
      setImageStore((prev) => [...prev, ...(confirmedPhotos as unknown as Slide[])]);
      setConfirmedPhotos([]);
      console.log(imageStore, 'check image store123');
    }
  }, [confirmedPhotos]);

  return (
    <Modal
      hideCloseButton
      classNames={{
        body: 'py-6 bg-white-50',
        backdrop: 'bg-[#292f46]/50 backdrop-opacity-40',
        base: 'border-[#292f46] bg-white',
      }}
      isOpen={isOpen}
      radius='none'
      size='5xl'
      onClose={onClose}
    >
      <ModalContent>
        <ModalBody className='min-h-[20rem]'>
          <div className='flex justify-between border-b border-gray-50 pb-4'>
            <p className={cn(heading({ size: 'h5' }))}>Edit Gallery</p>

            <div className='flex gap-2'>
              <Button
                className={cn(body({ weight: 'bold' }), 'border-gray-100')}
                radius='none'
                variant='ghost'
                onClick={() => fileInputRef.current?.click()}
              >
                <UploadSimple className='text-primary' size={24} />
                Upload images
              </Button>

              <Button
                className={cn(body({ weight: 'bold' }), 'mr-3 border-gray-100')}
                radius='none'
                variant='ghost'
                onClick={onGalleryCodeModalOpen}
              >
                <Hash className='text-primary' size={24} />
                Gallery code
              </Button>

              <Button
                className={cn(body({ weight: 'bold' }), 'w-[7.625rem] border-gray-100')}
                radius='none'
                variant='ghost'
                onClick={() => {
                  setImageStore([]);
                  onClose();
                }}
              >
                Cancel
              </Button>
              <Button
                className={cn(body({ weight: 'bold' }), 'w-[7.625rem]')}
                color='primary'
                // isLoading={isLoading}
                radius='none'
                onClick={handleDone}
              >
                Done
              </Button>
            </div>
          </div>

          <div className='grid max-h-[32rem] grid-cols-3 gap-4 overflow-y-auto'>
            {imageStore.map((image) => (
              <div key={image.id} className='relative h-[15.1rem] bg-black'>
                <Button
                  isIconOnly
                  className='absolute right-2 top-2 z-10 bg-gray-800/50 p-1'
                  radius='full'
                  size='sm'
                  variant='flat'
                  onClick={() => handleRemoveImage(image.id)}
                >
                  <X className='text-white-100' size={20} />
                </Button>
                <img
                  alt='Your alt text'
                  className='h-full w-full object-cover'
                  src={image.image.url}
                />
              </div>
            ))}
          </div>

          <input
            ref={fileInputRef}
            multiple
            accept='.jpg,.jpeg,.png,.webp,.gif'
            className='hidden'
            type='file'
            onChange={(event) => handleFileChange(event, true)}
          />

          <GalleryCodeModal
            // confirmedPhotos={confirmedPhotos}
            isOpen={isGalleryCodeModalOpen}
            setConfirmedPhotos={setConfirmedPhotos}
            onClose={onGalleryCodeModalClose}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
