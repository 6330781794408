'use client';

import { Autocomplete, AutocompleteItem, AutocompleteProps } from '@nextui-org/autocomplete';
import { cn } from '@nextui-org/theme';
import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { BoldChevronDownSvg } from '@/assets/icons';
import { body } from '@/theme/typography';

interface RHFAutocompleteItems {
  label: string | number;
  value: string | number;
}

interface RHFAutocompleteProps extends Partial<AutocompleteProps<RHFAutocompleteItems>> {
  name: string;
  items: RHFAutocompleteItems[];
  inputRef?: React.RefObject<HTMLInputElement>;
}

const RHFAutocomplete: React.FC<RHFAutocompleteProps> = ({
  name,
  items,
  onSelectionChange,
  inputRef,
  ...others
}) => {
  const { control } = useFormContext<Record<string, string>>();
  const [filteredItems, setFilteredItems] = React.useState<RHFAutocompleteItems[]>(items);

  const handleInputChange = (value: string) => {
    if (value === '') {
      setFilteredItems(items);
    } else {
      const filtered = items.filter((item) =>
        item.label.toString().toLowerCase().includes(value.toLowerCase()),
      );

      setFilteredItems(filtered);
    }
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, onBlur, value } }) => (
        <Autocomplete
          ref={inputRef}
          inputProps={{
            classNames: {
              inputWrapper: cn(
                'shadow-none bg-neutral-white',
                'data-[hover=true]:!bg-neutral-white group-data-[hover=true]:!bg-neutral-white',
                'data-[focus=true]:!bg-neutral-white group-data-[focus=true]:!bg-neutral-white',
              ),
              input: 'text-neutral-black',
            },
          }}
          radius='none'
          {...others}
          items={filteredItems}
          popoverProps={{ offset: 10, radius: 'none' }}
          selectedKey={value}
          selectorIcon={<BoldChevronDownSvg className='w-5 text-gray-800' />}
          onBlur={onBlur}
          onInputChange={(value) => handleInputChange(value)}
          onOpenChange={(isOpen) => {
            if (isOpen) {
              setFilteredItems(items);
            }
          }}
          onSelectionChange={async (value) => {
            onChange({ target: { value } });
            onSelectionChange?.(value);
          }}
        >
          {({ label, value }) => (
            <AutocompleteItem
              key={value}
              showDivider
              className='rounded-none data-[hover=true]:bg-white-300'
              classNames={{ title: body() }}
              value={value}
            >
              {label}
            </AutocompleteItem>
          )}
        </Autocomplete>
      )}
    />
  );
};

export default RHFAutocomplete;
