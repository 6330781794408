import { AxiosError } from 'axios';

import { publicApi } from '@/config/api';
import { UserProfile } from '@/models/user';
import {
  GetUserListFollowersApiResponse,
  GetUserListFollowingApiResponse,
  GetUserProfileApiResponse,
  UpdateUserProfileApiRequest,
} from '@/types/apis/user';

const SERVICE_PATH = '/profile';

export const getUserProfile = async (username: string, token?: string) => {
  try {
    const { data: res } = await publicApi<GetUserProfileApiResponse>({
      url: `${SERVICE_PATH}/${username}`,
      ...(token && { headers: { Authorization: `Bearer ${token}` } }),
    });

    return {
      ...res,
      profile: {
        ...res.profile,
        createdAt: new Date(res.profile.createdAt),
        updatedAt: new Date(res.profile.updatedAt),
      },
    } as UserProfile;
  } catch (error) {
    const err = error as AxiosError;

    // eslint-disable-next-line no-console
    console.error('`getUserProfile` API Error', err);

    return null;
  }
};

export const updateUserProfile = async (data: UpdateUserProfileApiRequest, token?: string) =>
  publicApi<GetUserProfileApiResponse>({
    url: SERVICE_PATH,
    method: 'PUT',
    data,
    headers: { Authorization: `Bearer ${token}` },
  });

// Follow and Unfollow

export const getUserFollowers = async (username: string, token?: string) => {
  try {
    const { data: res } = await publicApi<GetUserListFollowersApiResponse>({
      url: `/followers/${username}`,
      ...(token && { headers: { Authorization: `Bearer ${token}` } }),
    });

    return res.followers;
  } catch (error) {
    const err = error as AxiosError;

    // eslint-disable-next-line no-console
    console.error('`getUserProfile` API Error', err);

    return null;
  }
};

export const getUserFollowing = async (username: string, token?: string) => {
  try {
    const { data: res } = await publicApi<GetUserListFollowingApiResponse>({
      url: `/following/${username}`,
      ...(token && { headers: { Authorization: `Bearer ${token}` } }),
    });

    return res.following;
  } catch (error) {
    const err = error as AxiosError;

    // eslint-disable-next-line no-console
    console.error('`getUserProfile` API Error', err);

    return null;
  }
};

export const followUser = async (username: string, token?: string) =>
  publicApi({
    url: `/follow/${username}`,
    method: 'POST',
    headers: { Authorization: `Bearer ${token}` },
  });

export const unfollowUser = async (username: string, token?: string) =>
  publicApi({
    url: `/unfollow/${username}`,
    method: 'POST',
    headers: { Authorization: `Bearer ${token}` },
  });
