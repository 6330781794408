import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';

import {
  createSavedList,
  deleteSavedList,
  saveArticleToSavedList,
  unsaveArticleFromSavedList,
  updateSavedList,
} from '@/services/apis/saved-list';
import { CreateSavedListApiRequest, UpdateSavedListApiRequest } from '@/types/apis/saved-list';
import { SavedList } from '@/models/saved-list';

const useSavedListMutation = () => {
  const queryClient = useQueryClient();
  const { data: session } = useSession();

  const createMutation = useMutation({
    mutationFn: async (data: CreateSavedListApiRequest) => {
      if (!session?.token) throw new Error('User is not logged in');

      const newList = await createSavedList({
        data,
        token: session.token,
      });

      return newList;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['saved-lists'],
      });
    },
  });

  const updateMutation = useMutation({
    mutationFn: async ({ id, data }: { id: SavedList['id']; data: UpdateSavedListApiRequest }) => {
      if (!session?.token) throw new Error('User is not logged in');

      await updateSavedList({
        id,
        data,
        token: session.token,
      });
    },
    onSuccess: async (_, { id }) => {
      await queryClient.invalidateQueries({
        queryKey: ['saved-lists'],
      });
      await queryClient.invalidateQueries({
        queryKey: ['saved-list', id],
      });
    },
  });

  const deleteMutation = useMutation({
    mutationFn: async (id: SavedList['id']) => {
      if (!session?.token) throw new Error('User is not logged in');

      await deleteSavedList(id, session.token);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['saved-lists'],
      });
    },
  });

  const saveArticleMutation = useMutation({
    mutationFn: async ({ listId, articleId }: { listId: SavedList['id']; articleId: number }) => {
      if (!session?.token) throw new Error('User is not logged in');

      await saveArticleToSavedList(listId, articleId, session.token);
    },
    onSuccess: async (_, { listId }) => {
      await queryClient.invalidateQueries({
        queryKey: ['saved-lists'],
      });
      await queryClient.invalidateQueries({
        queryKey: ['saved-articles', listId],
      });
    },
  });

  const unsaveArticleMutation = useMutation({
    mutationFn: async ({ listId, articleId }: { listId: SavedList['id']; articleId: number }) => {
      if (!session?.token) throw new Error('User is not logged in');

      await unsaveArticleFromSavedList(listId, articleId, session.token);
    },
    onSuccess: async (_, { listId }) => {
      await queryClient.invalidateQueries({
        queryKey: ['saved-lists'],
      });
      await queryClient.invalidateQueries({
        queryKey: ['saved-articles', listId],
      });
    },
  });

  return {
    createMutation,
    updateMutation,
    deleteMutation,

    saveArticleMutation,
    unsaveArticleMutation,
  };
};

export default useSavedListMutation;
