import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';

import { CreateMediaApiRequest } from '@/types/apis/media';
import { createMedia, deleteMedia } from '@/services/apis/media';
import { Media } from '@/models/media';

const useMediaMutation = () => {
  const queryClient = useQueryClient();
  const { data: session } = useSession();

  const createMutation = useMutation({
    mutationFn: async (data: CreateMediaApiRequest) => {
      if (!session?.token) throw new Error('User is not logged in');

      await createMedia({
        data,
        token: session.token,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['media-list'],
      });
    },
  });

  const deleteMutation = useMutation({
    mutationFn: async (id: Media['id']) => {
      if (!session?.token) throw new Error('User is not logged in');

      await deleteMedia(id, session.token);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['media-list'],
      });
    },
  });

  return {
    createMutation,
    deleteMutation,
  };
};

export default useMediaMutation;
