import { useQuery } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';

import { getSavedArticlesByListId } from '@/services/apis/saved-list';

const useSavedArticles = (id: number) => {
  const { data: session } = useSession();

  const { data, ...others } = useQuery({
    queryKey: ['saved-articles', id],
    queryFn: () => getSavedArticlesByListId(id, session?.token!),
    staleTime: 10 * 1000,
    select: (data) => data,
    enabled: !!id && !!session?.token,
  });

  return {
    articles: data,
    ...others,
  };
};

export default useSavedArticles;
