import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalProps,
} from '@nextui-org/modal';
import { cn } from '@nextui-org/theme';
import React from 'react';
import { Button, ButtonProps } from '@nextui-org/button';

import { heading, paragraph } from '@/theme/typography';

export interface ConfirmationModalProps extends Omit<ModalProps, 'children'> {
  title: string;
  icon?: React.ReactNode;
  message: React.ReactNode;
  cancelText?: string;
  confirmText?: string;
  classNames?: ModalProps['classNames'] & {
    cancelButton?: string;
    confirmButton?: string;
  };
  cancelButtonProps?: Omit<ButtonProps, 'children' | 'onClick'>;
  confirmButtonProps?: Omit<ButtonProps, 'children' | 'onClick'>;
  onConfirm?: () => Promise<void> | void;
  onCancel?: () => Promise<void> | void;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  title,
  icon,
  message,
  className,
  classNames,
  cancelText = 'Cancel',
  confirmText = 'Confirm',
  cancelButtonProps,
  confirmButtonProps,
  onCancel,
  onConfirm,
  ...others
}) => {
  return (
    <Modal
      shouldBlockScroll
      radius='none'
      scrollBehavior='inside'
      onClose={onCancel}
      {...others}
      className={cn(className)}
      classNames={{
        base: 'max-w-lg',
        closeButton: 'top-4 right-4 text-[24px] rounded-none',
        ...classNames,
      }}
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader
              className={cn(
                heading({ size: 'h5', weight: 'medium' }),
                'flex flex-row items-center gap-3 p-6',
              )}
            >
              {icon}
              <span>{title}</span>
            </ModalHeader>

            <ModalBody className={cn(paragraph(), 'gap-8')}>{message}</ModalBody>

            <ModalFooter className='gap-4 p-6'>
              <Button
                radius='none'
                size='lg'
                variant='bordered'
                {...cancelButtonProps}
                className={cn(classNames?.cancelButton)}
                onClick={async () => {
                  await onCancel?.();
                  onClose();
                }}
              >
                {cancelText}
              </Button>
              <Button
                className={cn(classNames?.confirmButton)}
                color='primary'
                radius='none'
                size='lg'
                {...confirmButtonProps}
                onClick={async () => {
                  await onConfirm?.();
                  onClose();
                }}
              >
                {confirmText}
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default ConfirmationModal;
