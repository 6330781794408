'use client';

import { Button } from '@nextui-org/button';
import { cn } from '@nextui-org/theme';
import { BookmarkSimple } from '@phosphor-icons/react/dist/ssr';
import * as React from 'react';
import { Popover, PopoverTrigger, PopoverContent } from '@nextui-org/popover';
import { Listbox, ListboxItem } from '@nextui-org/listbox';
import { Checkbox } from '@nextui-org/checkbox';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';

import { useArticleSavedLists, useMySavedLists } from '@/hooks/queries';
import { heading } from '@/theme/typography';
import { CreateSavedListModal } from '@/modals';
import { useSavedListMutation } from '@/hooks/mutations';
import { SavedList } from '@/models/saved-list';

interface CheckboxItemProps {
  name: SavedList['name'];

  isSelected?: boolean;
  onSaveToggle: (checked: boolean) => Promise<void>;
}

const CheckboxItem: React.FC<CheckboxItemProps> = ({ name, isSelected, onSaveToggle }) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const handleToggle = async (checked: boolean) => {
    setIsLoading(true);
    await onSaveToggle(checked);
    setIsLoading(false);
  };

  return (
    <Checkbox
      className='w-full py-5 outline-none'
      classNames={{
        label: 'w-full',
        base: 'max-w-full',
        wrapper: 'mr-3',
      }}
      color='primary'
      isDisabled={isLoading}
      isSelected={isSelected}
      radius='none'
      onValueChange={handleToggle}
    >
      {name}
    </Checkbox>
  );
};

interface SavedButtonProps {
  articleId: number;
}
interface CreateSavedListFormAttributes {
  name: string;
}

const SavedButton: React.FC<SavedButtonProps> = ({ articleId }) => {
  const { savedLists: mySavedLists } = useMySavedLists();
  const { savedLists: articleSavedLists, isLoading, refetch } = useArticleSavedLists(articleId);

  const [isSavedListPopoverOpen, setIsSavedListPopoverOpen] = React.useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = React.useState(false);

  const { saveArticleMutation, unsaveArticleMutation } = useSavedListMutation();
  const [saveAll, setSaveAll] = React.useState(false);
  const { createMutation } = useSavedListMutation();
  const method = useForm<CreateSavedListFormAttributes>({
    disabled: createMutation.isPending,
  });

  const handleClosePopover = () => {
    setIsSavedListPopoverOpen(false);
  };

  const handleSaveArticle = async (list: SavedList) => {
    await saveArticleMutation.mutateAsync(
      { articleId, listId: list.id },
      {
        onSuccess: () => {
          toast(`Saved to ${list.name} list`);
        },
        onError: () => {
          toast.error(`Failed to save to ${list.name} list`);
        },
      },
    );
  };

  const handleUnsaveArticle = async (list: SavedList) => {
    await unsaveArticleMutation.mutateAsync(
      { articleId, listId: list.id },
      {
        onSuccess: () => {
          toast(`Unsaved from ${list.name} list`);
        },
        onError: () => {
          toast.error(`Failed to unsave from ${list.name} list`);
        },
      },
    );
  };

  const mySavedListsArray = React.useMemo(
    () =>
      (mySavedLists?.pages.flat().filter((list) => !!list) as SavedList[])
        // Filter out the list that the article is already saved to
        ?.filter((list) => articleSavedLists?.every((articleList) => articleList.id !== list.id)) ??
      [],
    [articleSavedLists, mySavedLists],
  );

  const isSaved = React.useMemo(() => (articleSavedLists?.length ?? 0) > 0, [articleSavedLists]);

  const handleSaveAllClick = async () => {
    if (!articleSavedLists || !mySavedLists) return;

    try {
      const selectedSaveAllList = articleSavedLists?.find((list) => list.name === 'Save all');
      const saveAllList = mySavedLists?.pages.flat().find((list) => list?.name === 'Save all');

      if (!saveAllList) {
        const newList = await createMutation.mutateAsync({ name: 'Save all' });

        await handleSaveArticle(newList);
        refetch();
      } else if (saveAllList) {
        if (selectedSaveAllList) {
          await handleUnsaveArticle(selectedSaveAllList);
          refetch();
          toast.success('Article removed from "Save all" list');
        } else {
          await handleSaveArticle(saveAllList);
          refetch();
          toast.success('Article saved to "Save all" list');
        }
      }
    } catch (error) {
      toast.error('Failed to create and save to "Save all" list');
    }
  };

  return (
    <>
      <Popover
        showArrow
        classNames={{
          content: 'p-4 border-none bg-background rounded-none',
        }}
        isOpen={isSavedListPopoverOpen}
        offset={12}
        placement='bottom'
        onClose={handleClosePopover}
      >
        <PopoverTrigger>
          <Button
            isIconOnly
            className='text-gray-600'
            isDisabled={isLoading}
            radius='none'
            variant='light'
            onClick={handleSaveAllClick}
            onMouseEnter={() => setIsSavedListPopoverOpen(true)}
          >
            <BookmarkSimple
              className={cn({ 'text-primary': isSaved })}
              size={24}
              weight={isSaved ? 'fill' : 'regular'}
            />
          </Button>
        </PopoverTrigger>

        <PopoverContent className='flex min-w-72 flex-col gap-2' onMouseLeave={handleClosePopover}>
          <header className={cn(heading({ size: 'h6', weight: 'medium' }))}>Lists</header>

          <div className='flex w-full flex-col items-start gap-2'>
            <Listbox items={[...(articleSavedLists ?? []), ...(mySavedListsArray ?? [])]}>
              {(list) => (
                <ListboxItem
                  key={list.id}
                  showDivider
                  className='gap-3 rounded-none px-0 py-0'
                  variant='light'
                >
                  <CheckboxItem
                    key={list.id}
                    isSelected={articleSavedLists?.some((l) => l.id === list.id)}
                    name={list.name}
                    onSaveToggle={(checked) =>
                      (checked ? handleSaveArticle : handleUnsaveArticle)(list)
                    }
                  />
                </ListboxItem>
              )}
            </Listbox>

            <Button
              color='primary'
              radius='none'
              variant='light'
              onClick={() => {
                handleClosePopover();
                setIsCreateModalOpen(true);
              }}
            >
              Create new list
            </Button>
          </div>
        </PopoverContent>
      </Popover>

      <CreateSavedListModal isOpen={isCreateModalOpen} onOpenChange={setIsCreateModalOpen} />
    </>
  );
};

export default SavedButton;
