import { BubbleMenu as BaseBubbleMenu, Editor, useEditorState } from '@tiptap/react';
import { useCallback } from 'react';
import { sticky } from 'tippy.js';
import { v4 as uuid } from 'uuid';
import { SidebarSimple, SquareSplitHorizontal } from '@phosphor-icons/react/dist/ssr';
import { Button } from '@nextui-org/button';
import { cn } from '@nextui-org/theme';
import { Divider } from '@nextui-org/divider';

import { ColumnLayout } from '../../extensions/MultiColumn/Columns';
import { getRenderContainer } from '../../utils';

interface ColumnsMenuProps {
  editor: Editor;
  appendTo?: React.RefObject<any>;
}

export const ColumnsMenu: React.FC<ColumnsMenuProps> = ({ editor, appendTo }) => {
  const getReferenceClientRect = useCallback(() => {
    const renderContainer = getRenderContainer(editor, 'columns');
    const rect = renderContainer?.getBoundingClientRect() || new DOMRect(-1000, -1000, 0, 0);

    return rect;
  }, [editor]);

  const shouldShow = useCallback(() => {
    const isColumns = editor.isActive('columns');

    return isColumns;
  }, [editor]);

  const onColumnLeft = useCallback(() => {
    editor.chain().focus().setLayout(ColumnLayout.SidebarLeft).run();
  }, [editor]);

  const onColumnRight = useCallback(() => {
    editor.chain().focus().setLayout(ColumnLayout.SidebarRight).run();
  }, [editor]);

  const onColumnTwo = useCallback(() => {
    editor.chain().focus().setLayout(ColumnLayout.TwoColumn).run();
  }, [editor]);

  const { isColumnLeft, isColumnRight, isColumnTwo } = useEditorState({
    editor,
    selector: (ctx) => {
      return {
        isColumnLeft: ctx.editor.isActive('columns', { layout: ColumnLayout.SidebarLeft }),
        isColumnRight: ctx.editor.isActive('columns', { layout: ColumnLayout.SidebarRight }),
        isColumnTwo: ctx.editor.isActive('columns', { layout: ColumnLayout.TwoColumn }),
      };
    },
  });

  return (
    <BaseBubbleMenu
      editor={editor}
      pluginKey={`columnsMenu-${uuid()}`}
      shouldShow={shouldShow}
      tippyOptions={{
        offset: [0, 8],
        popperOptions: {
          modifiers: [{ name: 'flip', enabled: false }],
        },
        getReferenceClientRect,
        appendTo: () => appendTo?.current,
        plugins: [sticky],
        sticky: 'popper',
      }}
      updateDelay={0}
    >
      <div className='flex flex-row items-center border border-gray-100 bg-background'>
        <Button
          isIconOnly
          className={cn({
            'text-primary': isColumnLeft,
          })}
          radius='none'
          variant='light'
          onClick={onColumnLeft}
        >
          <SidebarSimple size={24} />
        </Button>

        <Divider className='h-6' orientation='vertical' />

        <Button
          isIconOnly
          className={cn({
            'text-primary': isColumnTwo,
          })}
          radius='none'
          variant='light'
          onClick={onColumnTwo}
        >
          <SquareSplitHorizontal size={24} />
        </Button>

        <Divider className='h-6' orientation='vertical' />

        <Button
          isIconOnly
          className={cn({
            'text-primary': isColumnRight,
          })}
          radius='none'
          variant='light'
          onClick={onColumnRight}
        >
          <SidebarSimple className='rotate-180' size={24} />
        </Button>
      </div>
    </BaseBubbleMenu>
  );
};

export default ColumnsMenu;
