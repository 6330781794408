'use client';

import { cn } from '@nextui-org/theme';
import * as React from 'react';
import parse, {
  DOMNode,
  domToReact,
  Element,
  HTMLReactParserOptions,
  Text,
} from 'html-react-parser';

import { heading, paragraph } from '@/theme/typography';

interface RichTextProps {
  content: string;
  className?: string;
}

const htmlParserOptions: HTMLReactParserOptions = {
  replace: (domNode) => {
    if (domNode instanceof Element) {
      if (domNode.children[0] instanceof Text && domNode.children[0].data.trim() === '')
        return <br />;

      if (['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(domNode.name))
        return (
          <h2 className={heading({ size: 'h4' })}>
            {domToReact(domNode.children as DOMNode[], htmlParserOptions)}
          </h2>
        );

      if (domNode.name === 'p')
        return (
          <p className={paragraph()}>
            {domToReact(domNode.children as DOMNode[], htmlParserOptions)}
          </p>
        );

      if (['ul', 'ol'].includes(domNode.name))
        return (
          <ul className='flex list-outside list-disc flex-col gap-2 pl-4'>
            {domToReact(domNode.children as DOMNode[], htmlParserOptions)}
          </ul>
        );

      if (domNode.name === 'li') {
        return (
          <li className={cn(paragraph(), 'text-primary')}>
            <span className='text-neutral-black'>
              {domToReact(domNode.children as DOMNode[], htmlParserOptions)}
            </span>
          </li>
        );
      }
    }
  },
};

const RichText: React.FC<RichTextProps> = ({ content, className }) => (
  <section className={cn('flex flex-col gap-6', className)}>
    {parse(content, htmlParserOptions)}
  </section>
);

export default RichText;
