'use client';

import { cn } from '@nextui-org/theme';
import { Editor, NodeViewWrapper } from '@tiptap/react';
import React from 'react';
import { VideoCamera } from '@phosphor-icons/react';
import { Input } from '@nextui-org/input';
import { Button } from '@nextui-org/button';

interface EmbedEditorViewProps {
  getPos: () => number;
  editor: Editor;
}

export const EmbedEditorView: React.FC<EmbedEditorViewProps> = ({ getPos, editor }) => {
  const [url, setUrl] = React.useState('');

  const handleAddEmbed = React.useCallback(() => {
    editor
      .chain()
      .setYoutubeVideo({
        src: url,
      })
      .deleteRange({ from: getPos(), to: getPos() })
      .focus()
      .run();
  }, [getPos, editor, url]);

  return (
    <NodeViewWrapper>
      <div
        data-drag-handle
        className={cn(
          'flex flex-row items-center justify-center gap-3 border border-gray-100 bg-neutral-white p-2',
        )}
        contentEditable={false}
      >
        <VideoCamera className='ml-2 text-primary' size={24} />

        <Input
          classNames={{ inputWrapper: '!bg-transparent shadow-none' }}
          name='url'
          placeholder='Paste or type a URL from YouTube'
          radius='none'
          size='sm'
          value={url}
          variant='flat'
          onValueChange={setUrl}
        />

        <Button
          className='h-8 min-w-min px-3'
          color='primary'
          radius='none'
          variant='light'
          onClick={handleAddEmbed}
        >
          Add
        </Button>
      </div>
    </NodeViewWrapper>
  );
};

export default EmbedEditorView;
