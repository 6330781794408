import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';

import { followUser, unfollowUser } from '@/services/apis/user';

const useUserFollowMutation = () => {
  const queryClient = useQueryClient();
  const { data: session } = useSession();

  const followMutation = useMutation({
    mutationFn: async (username: string) => {
      if (!session?.token) throw new Error('User is not logged in');

      await followUser(username, session.token);
    },
    onSuccess: async (_, username) => {
      await queryClient.invalidateQueries({
        queryKey: ['followers', username],
      });
      // Invalidate the followings query for the logged in user
      await queryClient.invalidateQueries({
        queryKey: ['followings', session?.user.username],
      });
    },
  });

  const unfollowMutation = useMutation({
    mutationFn: async (username: string) => {
      if (!session?.token) throw new Error('User is not logged in');

      await unfollowUser(username, session.token);
    },
    onSuccess: async (_, username) => {
      await queryClient.invalidateQueries({
        queryKey: ['followers', username],
      });
      // Invalidate the followings query for the logged in user
      await queryClient.invalidateQueries({
        queryKey: ['followings', session?.user.username],
      });
    },
  });

  return {
    followMutation,
    unfollowMutation,
  };
};

export default useUserFollowMutation;
