'use client';

import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { Editor } from '@tiptap/core';
import { cn } from '@nextui-org/theme';
import { Divider } from '@nextui-org/divider';

import { Command, Group } from './types';

import { body, caption } from '@/theme/typography';

interface MenuListProps {
  editor: Editor;
  items: Group[];
  command: (command: Command) => void;
}

export const MenuList = React.forwardRef<unknown, MenuListProps>(({ items, command }, ref) => {
  const scrollContainer = useRef<HTMLDivElement>(null);
  const activeItem = useRef<HTMLButtonElement>(null);
  const [selectedGroupIndex, setSelectedGroupIndex] = useState(0);
  const [selectedCommandIndex, setSelectedCommandIndex] = useState(0);

  /**
   * Anytime the groups change, i.e. the user types to narrow it down,
   * we want to reset the current selection to the first menu item
   */
  useEffect(() => {
    setSelectedGroupIndex(0);
    setSelectedCommandIndex(0);
  }, [items]);

  const selectItem = useCallback(
    (groupIndex: number, commandIndex: number) => {
      const itemCommand = items[groupIndex].commands[commandIndex];

      command(itemCommand);
    },
    [items, command],
  );

  React.useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }: { event: React.KeyboardEvent }) => {
      if (event.key === 'ArrowDown') {
        if (!items.length) return false;

        const commands = items[selectedGroupIndex].commands;

        let newCommandIndex = selectedCommandIndex + 1;
        let newGroupIndex = selectedGroupIndex;

        if (commands.length - 1 < newCommandIndex) {
          newCommandIndex = 0;
          newGroupIndex = selectedGroupIndex + 1;
        }

        if (items.length - 1 < newGroupIndex) {
          newGroupIndex = 0;
        }

        setSelectedCommandIndex(newCommandIndex);
        setSelectedGroupIndex(newGroupIndex);

        return true;
      }

      if (event.key === 'ArrowUp') {
        if (!items.length) return false;

        let newCommandIndex = selectedCommandIndex - 1;
        let newGroupIndex = selectedGroupIndex;

        if (newCommandIndex < 0) {
          newGroupIndex = selectedGroupIndex - 1;
          newCommandIndex = items[newGroupIndex]?.commands.length - 1 || 0;
        }

        if (newGroupIndex < 0) {
          newGroupIndex = items.length - 1;
          newCommandIndex = items[newGroupIndex].commands.length - 1;
        }

        setSelectedCommandIndex(newCommandIndex);
        setSelectedGroupIndex(newGroupIndex);

        return true;
      }

      if (event.key === 'Enter') {
        if (!items.length || selectedGroupIndex === -1 || selectedCommandIndex === -1) return false;

        selectItem(selectedGroupIndex, selectedCommandIndex);

        return true;
      }

      return false;
    },
  }));

  useEffect(() => {
    if (activeItem.current && scrollContainer.current) {
      const offsetTop = activeItem.current.offsetTop;
      const offsetHeight = activeItem.current.offsetHeight;

      scrollContainer.current.scrollTop = offsetTop - offsetHeight;
    }
  }, [selectedCommandIndex, selectedGroupIndex]);

  const createCommandClickHandler = useCallback(
    (groupIndex: number, commandIndex: number) => {
      return () => {
        selectItem(groupIndex, commandIndex);
      };
    },
    [selectItem],
  );

  if (!items.length) return null;

  return (
    <div
      ref={scrollContainer}
      className='mb-8 max-h-[min(80vh,24rem)] min-w-80 max-w-full flex-wrap overflow-auto p-3 text-black shadow-medium'
    >
      <div className='flex flex-col'>
        {items.map(({ title, commands }, groupIndex) => (
          <Fragment key={`${title}-wrapper`}>
            <label
              className={cn(
                caption({ weight: 'medium' }),
                'm-2 mt-6 select-none text-gray-500 first:mt-1',
              )}
            >
              {title}
            </label>

            {commands.map(({ label, icon: Icon }, commandIndex) => (
              <Fragment key={label}>
                <button
                  ref={
                    selectedGroupIndex === groupIndex && selectedCommandIndex === commandIndex
                      ? activeItem
                      : null
                  }
                  className={cn(
                    body(),
                    'flex items-center justify-start gap-4 p-3',
                    'bg-transparent hover:bg-neutral-50',
                    {
                      'bg-neutral-100 hover:bg-neutral-100':
                        selectedGroupIndex === groupIndex && selectedCommandIndex === commandIndex,
                    },
                  )}
                  onClick={createCommandClickHandler(groupIndex, commandIndex)}
                >
                  {Icon && <Icon size={20} />}
                  <span>{label}</span>
                </button>

                <Divider />
              </Fragment>
            ))}
          </Fragment>
        ))}
      </div>
    </div>
  );
});

MenuList.displayName = 'MenuList';

export default MenuList;
