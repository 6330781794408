import { Chip } from '@nextui-org/chip';
import { cn } from '@nextui-org/theme';
import Link from 'next/link';

import ArticleContent from './ArticleContent';
import ArticleAside from './ArticleAside';
import ArticleAuthor from './ArticleAuthor';

import { FallbackImage, ArticleActionsSection, ArticleComment } from '@/components';
import { heading, paragraph } from '@/theme/typography';
import { Article, ArticleDetail } from '@/models/article';

interface ArticleLayoutProps {
  article: ArticleDetail;
  hasSidebar?: boolean;
  sidebarCategories?: {
    title: string;
    articles: Article[];
  }[];
  className?: string;
}

const ArticleLayout: React.FC<ArticleLayoutProps> = ({
  article,
  hasSidebar,
  sidebarCategories,
  className,
}) => {
  return (
    <div
      className={cn(
        'flex w-full flex-col gap-14 pb-14',
        { 'container xl:flex-row': hasSidebar },
        className,
      )}
    >
      <div className={cn('flex w-full flex-col', { 'xl:w-2/3': hasSidebar })}>
        <div
          className={cn('flex w-full max-w-4xl flex-col items-start gap-8 self-center pb-8 pt-6', {
            container: !hasSidebar,
          })}
        >
          <hgroup className='flex flex-col gap-4'>
            {(article.categories?.length ?? 0) > 0 && (
              <div className='flex flex-row flex-wrap gap-2'>
                {article.categories?.map(({ id, name }) => (
                  <Link key={id} href={`/guides/category/${name}`}>
                    <Chip
                      as='h2'
                      className='bg-gray-100 uppercase text-gray-800'
                      radius='none'
                      size='lg'
                    >
                      {name}
                    </Chip>
                  </Link>
                ))}
              </div>
            )}

            <h1 className={heading({ size: 'h2' })}>{article.title}</h1>

            <p className={cn(paragraph(), 'text-gray-600')}>{article.description}</p>
          </hgroup>

          {article.author && (
            <ArticleAuthor author={article.author} createdAt={article.createdAt} />
          )}

          <ArticleActionsSection article={article} className='w-full' />
        </div>

        <div className='relative aspect-video max-h-[640px] w-full'>
          <FallbackImage
            fill
            alt={article.title}
            className='bg-white-100 object-cover'
            src={article.cover?.url ?? ''}
          />
        </div>

        <ArticleContent
          className={cn({ container: !hasSidebar })}
          contents={article.content ?? []}
        />

        <section className='my-14 flex w-full max-w-4xl flex-col gap-8 self-center'>
          <ArticleActionsSection article={article} />

          <ArticleComment articleId={article.id} authorId={article.author?.id} />
        </section>
      </div>

      {hasSidebar && sidebarCategories && (sidebarCategories.length ?? 0) > 0 && (
        <ArticleAside categories={sidebarCategories} />
      )}
    </div>
  );
};

export default ArticleLayout;
