import { useQuery } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';

import { getMe } from '@/services/apis/auth';

const useMe = () => {
  const { data: session } = useSession();

  const { data, ...others } = useQuery({
    queryKey: ['me'],
    queryFn: () => getMe(session?.token!),
    staleTime: 10 * 1000,
    select: (data) => data,
    enabled: !!session?.token,
  });

  return {
    me: data,
    ...others,
  };
};

export default useMe;
