'use client';

import { Image } from '@nextui-org/image';
import { NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import { useCallback, useRef } from 'react';

interface ImageGridViewProps extends NodeViewProps {}

export const ImageGridView: React.FC<ImageGridViewProps> = ({ editor, getPos, node }) => {
  const imageWrapperRef = useRef<HTMLDivElement>(null);
  const { urls } = node.attrs as Node & {
    urls: string[];
  };

  const onClick = useCallback(() => {
    editor.commands.setNodeSelection(getPos());
  }, [getPos, editor.commands]);

  // console.log(node.attrs);

  return (
    <NodeViewWrapper>
      <div data-drag-handle>
        <div className='grid grid-cols-3 gap-4'>
          {urls.map((url) => (
            <Image
              key={url}
              alt={url}
              className='h-full w-full object-cover'
              radius='none'
              src={url}
            />
          ))}
        </div>
      </div>
    </NodeViewWrapper>
  );
};

export default ImageGridView;
