'use client';

import { Button } from '@nextui-org/button';
import { cn } from '@nextui-org/theme';
import {
  BookmarkSimple,
  ChatsCircle,
  Export,
  FacebookLogo,
  LinkedinLogo,
  LinkSimpleHorizontal,
  XLogo,
} from '@phosphor-icons/react/dist/ssr';
import * as React from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownTrigger } from '@nextui-org/dropdown';

import { ReactButton } from '..';

interface IArticleActionsSectionProps {
  reactionCount?: number;
  commentCount?: number;

  onReact?: () => void;
  onComment?: () => void;
  previewOnly?: boolean;
  className?: string;
}

const ArticleActionsSection: React.FC<IArticleActionsSectionProps> = ({
  reactionCount = 0,
  commentCount = 0,
  onReact,
  onComment,
  className,
  previewOnly = false,
}) => {
  const scrollToComments = () => {
    const comments = document.getElementById('comments');

    if (!comments) return;
    window.scroll({
      top: comments.offsetTop - 128,
      behavior: 'smooth',
    });

    onComment?.();
  };

  return (
    <section
      className={cn(
        'relative flex flex-row items-center justify-between gap-6 border-y-1 border-y-divider py-2',
        className,
      )}
    >
      {previewOnly && <div className='absolute inset-0 z-20' />}

      <div className='flex flex-row items-center gap-2'>
        <ReactButton
          className='-ml-2 text-gray-600'
          count={reactionCount}
          radius='none'
          onClick={onReact}
        />

        <Button
          className={cn('flex w-auto min-w-0 flex-row items-center gap-2 px-2 text-gray-600')}
          radius='none'
          startContent={<ChatsCircle size={24} />}
          variant='light'
          onClick={scrollToComments}
        >
          {commentCount}
        </Button>
      </div>

      <div className='flex flex-row items-center gap-2'>
        <Button isIconOnly className='text-gray-600' radius='none' variant='light'>
          <BookmarkSimple size={24} weight='regular' />
        </Button>

        <Dropdown
          showArrow
          classNames={{
            content: 'border-none bg-background rounded-none',
          }}
          offset={10}
          placement='bottom-end'
          radius='sm'
          ria-label='Saved list actions'
        >
          <DropdownTrigger>
            <Button isIconOnly className='text-gray-600' radius='none' variant='light'>
              <Export size={24} />
            </Button>
          </DropdownTrigger>

          <DropdownMenu
            aria-label='Share article'
            items={[
              {
                key: 'copy-link',
                icon: <LinkSimpleHorizontal size={24} />,
                label: 'Copy link',
              },
              {
                key: 'x',
                icon: <XLogo size={24} />,
                label: 'Share on X',
              },
              {
                key: 'facebook',
                icon: <FacebookLogo size={24} />,
                label: 'Share on Facebook',
              },
              {
                key: 'linkedin',
                icon: <LinkedinLogo size={24} />,
                label: 'Share on LinkedIn',
                showDivider: false,
              },
            ]}
          >
            {({ key, icon, label, ...others }) => (
              <DropdownItem
                key={key}
                showDivider
                aria-label={label}
                startContent={icon}
                variant='light'
                {...others}
              >
                {label}
              </DropdownItem>
            )}
          </DropdownMenu>
        </Dropdown>
      </div>
    </section>
  );
};

export default ArticleActionsSection;
