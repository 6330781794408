'use client';

import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Select, SelectItem, SelectProps } from '@nextui-org/select';
import { cn } from '@nextui-org/theme';

import { body } from '@/theme/typography';

interface RHFSelectProps extends Omit<SelectProps, 'children'> {
  name: string;
  items: {
    key: string;
    label: string | number;
  }[];
}

const RHFSelect: React.FC<RHFSelectProps> = ({ name, classNames, ...others }) => {
  const { control } = useFormContext<Record<string, string | number>>();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange }, fieldState: { error }, formState: { disabled } }) => (
        <Select
          labelPlacement='outside'
          radius='none'
          variant='bordered'
          {...others}
          classNames={{
            ...classNames,
            trigger: cn('shadow-none', classNames?.trigger),
            label: cn(body({ weight: 'medium' }), classNames?.label),
            popoverContent: cn('rounded-none', classNames?.popoverContent),
          }}
          disabled={disabled}
          errorMessage={error?.message}
          isInvalid={!!error}
          selectedKeys={[value]}
          onChange={onChange}
        >
          {({ key, label }) => (
            <SelectItem
              key={key}
              showDivider
              className={cn(body())}
              variant='light'
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {label}
            </SelectItem>
          )}
        </Select>
      )}
    />
  );
};

export default RHFSelect;
