import { Editor } from '@tiptap/react';
import {
  TextAlignCenter,
  TextAlignLeft,
  TextAlignJustify,
  TextAlignRight,
  TextB,
  TextItalic,
  TextStrikethrough,
  TextUnderline,
} from '@phosphor-icons/react/dist/ssr';
import { Icon } from '@phosphor-icons/react';

export const TOOL_GROUPS: ToolGroup[] = [
  {
    type: 'marks',
    commands: [
      {
        name: 'text',
        icon: TextB,
        isActive: (editor: Editor) => editor.isActive('bold'),
        action: (editor) => {
          editor.chain().focus().toggleBold().run();
        },
      },
      {
        name: 'italic',
        icon: TextItalic,
        isActive: (editor: Editor) => editor.isActive('italic'),
        action: (editor) => {
          editor.chain().focus().toggleItalic().run();
        },
      },
      {
        name: 'underline',
        icon: TextUnderline,
        isActive: (editor: Editor) => editor.isActive('underline'),
        action: (editor) => {
          editor.chain().focus().toggleUnderline().run();
        },
      },
      {
        name: 'strike',
        icon: TextStrikethrough,
        isActive: (editor: Editor) => editor.isActive('strike'),
        action: (editor) => {
          editor.chain().focus().toggleStrike().run();
        },
      },
    ],
  },
  {
    type: 'text-align',
    commands: [
      {
        name: 'left',
        icon: TextAlignLeft,
        isActive: (editor: Editor) => editor.isActive({ textAlign: 'left' }),
        action: (editor) => {
          editor.chain().focus().setTextAlign('left').run();
        },
      },
      {
        name: 'center',
        icon: TextAlignCenter,
        isActive: (editor: Editor) => editor.isActive({ textAlign: 'center' }),
        action: (editor) => {
          editor.chain().focus().setTextAlign('center').run();
        },
      },
      {
        name: 'right',
        icon: TextAlignRight,
        isActive: (editor: Editor) => editor.isActive({ textAlign: 'right' }),
        action: (editor) => {
          editor.chain().focus().setTextAlign('right').run();
        },
      },
      {
        name: 'justify',
        icon: TextAlignJustify,
        isActive: (editor: Editor) => editor.isActive({ textAlign: 'justify' }),
        action: (editor) => {
          editor.chain().focus().setTextAlign('justify').run();
        },
      },
    ],
  },
  // {
  //   type: 'another-marks',
  //   commands: [
  //     {
  //       name: 'link',
  //       icon: Link,
  //       isActive: (editor: Editor) => editor.isActive('link'),
  //       // action: (editor) => {
  //       //   editor.chain().focus().toggleLink().run();
  //       // },
  //     },
  //     {
  //       name: 'highlight',
  //       icon: Highlighter,
  //       isActive: (editor: Editor) => editor.isActive('highlight'),
  //       action: (editor) => {
  //         editor.chain().focus().toggleHighlight().run();
  //       },
  //     },
  //   ],
  // },
];

export const NODES = [
  { type: 'paragraph', label: 'Text' },
  { type: 'h1', label: 'Heading 1' },
  { type: 'h2', label: 'Heading 2' },
  { type: 'h3', label: 'Heading 3' },
  { type: 'bulletList', label: 'Bulleted list' },
  { type: 'orderedList', label: 'Numbered list' },
  { type: 'blockquote', label: 'Quote' },
  { type: 'table', label: 'Table' },
];

interface ToolGroup {
  type: string;
  commands: {
    name: string;
    icon: Icon;
    isActive: (editor: Editor) => boolean;
    action?: (editor: Editor) => void;
  }[];
}

type NodeLabels = { [K in (typeof NODES)[number]['type']]: (typeof NODES)[number]['label'] };

export type NodeType = (typeof NODES)[number]['type'];

export const NodesTypeLabelEnum: NodeLabels = NODES.reduce((acc, node) => {
  acc[node.type] = node.label;

  return acc;
}, {} as NodeLabels);

export const NodeTypeEnum: { [key in NodeType]: key } = NODES.reduce(
  (acc, node) => {
    acc[node.type] = node.type;

    return acc;
  },
  {} as { [key in NodeType]: key },
);
