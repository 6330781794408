'use client';

import Image, { ImageProps } from 'next/image';
import { useEffect, useState } from 'react';

const FallbackImage: React.FC<ImageProps> = ({ ...others }) => {
  const [src, setSrc] = useState(others.src);

  useEffect(() => {
    setSrc(others.src);
  }, [others.src]);

  return (
    <Image
      style={{
        borderRadius: '4px',
        overflow: 'hidden',
      }}
      {...others}
      src={src}
      onError={() => setSrc('/assets/images/image-error.png')}
    />
  );
};

export default FallbackImage;
