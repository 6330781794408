import { Node, ReactNodeViewRenderer } from '@tiptap/react';
import { mergeAttributes } from '@tiptap/core';

import { ImageUploadView } from './view/ImageUploadView';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    imageUpload: {
      setImageUpload: () => ReturnType;
      // setImageGrid: (attributes: { urls: string[] }) => ReturnType;
    };
  }
}

export const ImageUpload = Node.create({
  name: 'imageUpload',

  isolating: true,

  defining: true,

  group: 'block',

  draggable: true,

  selectable: true,

  inline: false,

  addAttributes() {
    return {
      urls: {
        default: [],
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
        'data-type': this.name,
      }),
    ];
  },

  addCommands() {
    return {
      setImageUpload:
        () =>
        ({ commands }) =>
          commands.insertContent(`<div data-type="${this.name}"></div>`),

      setImageGrid:
        (attrs) =>
        ({ commands }) => {
          return commands.insertContent({
            type: 'grid',
            attrs: { urls: attrs.urls },
          });
        },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(ImageUploadView);
  },
});

export default ImageUpload;
