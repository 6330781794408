import { AxiosRequestConfig } from 'axios';

import { publicApi } from '@/config/api';
import { CreateMediaApiRequest, GetMyMediaListApiResponse } from '@/types/apis/media';
import { Media } from '@/models/media';

const SERVICE_PATH = '/media';

export const getMyMediaList = async (
  token: string,
  { params }: { params?: AxiosRequestConfig['params'] },
) => {
  try {
    const { data: res } = await publicApi<GetMyMediaListApiResponse>({
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: SERVICE_PATH,
      params,
    });

    return res.results.map(
      (item) =>
        ({
          ...item,
          content: {
            ...item.content,

            createdAt: new Date(item.content.createdAt),
            updatedAt: new Date(item.content.updatedAt),
          },

          createdAt: new Date(item.createdAt),
          updatedAt: new Date(item.updatedAt),
        }) as Media,
    );
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('`getMyMediaList` API Error', error);

    return null;
  }
};

export const createMedia = async ({
  data: { content },
  token,
}: {
  data: CreateMediaApiRequest;
  token: string;
}) => {
  try {
    const data = new FormData();

    data.append('content', content, 'media');
    await publicApi({
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
      url: SERVICE_PATH,
      data,
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('`createMedia` API Error', error);
  }
};

export const deleteMedia = async (id: number, token: string) => {
  try {
    await publicApi({
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: `${SERVICE_PATH}/${id}`,
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('`deleteMedia` API Error', error);
  }
};
