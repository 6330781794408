import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';

import { updateUserProfile } from '@/services/apis/user';
import { UpdateUserProfileApiRequest } from '@/types/apis/user';
import { upload } from '@/services/apis/upload';

const useUserProfileMutation = () => {
  const queryClient = useQueryClient();
  const { data: session } = useSession();

  const updateMutation = useMutation({
    mutationFn: async ({ avatar, ...data }: UpdateUserProfileApiRequest) => {
      if (!session?.token) throw new Error('User is not logged in');

      const updateData: UpdateUserProfileApiRequest = { ...data };

      if (avatar instanceof File) {
        const formData = new FormData();

        formData.append('ref', 'plugin::users-permissions.user');
        formData.append('refId', session.user.id!.toString());
        formData.append('field', 'avatar');
        formData.append('files', avatar, 'file');

        await upload({ data: formData, token: session.token });
      }

      // If avatar is null, remove the avatar
      if (avatar === null) {
        updateData.avatar = null;
      }

      await updateUserProfile(updateData, session.token);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['me'],
      });
      await queryClient.invalidateQueries({
        queryKey: ['user-profile', session?.user.username],
      });
    },
  });

  return {
    updateMutation,
  };
};

export default useUserProfileMutation;
