import { create } from 'zustand';

interface GalleryShowCaseState {
  isGalleryShowCaseVisible: boolean;
  toggleGalleryShowCase: () => void;
  setGalleryShowCaseVisible: (visible: boolean) => void;
}

const useGalleryShowCaseStore = create<GalleryShowCaseState>((set) => ({
  isGalleryShowCaseVisible: false,
  toggleGalleryShowCase: () =>
    set((state) => ({ isGalleryShowCaseVisible: !state.isGalleryShowCaseVisible })),
  setGalleryShowCaseVisible: (visible: boolean) => set({ isGalleryShowCaseVisible: visible }),
}));

export default useGalleryShowCaseStore;
