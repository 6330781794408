import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';

import { CreateAlbumApiRequest, UpdateAlbumApiRequest } from '@/types/apis/album';
import { createALbum, deleteAlbum, updateAlbum } from '@/services/apis/album';
import { Album } from '@/models/album';

const useAlbumMutation = () => {
  const queryClient = useQueryClient();
  const { data: session } = useSession();

  const createMutation = useMutation({
    mutationFn: async (data: CreateAlbumApiRequest) => {
      if (!session?.token) throw new Error('User is not logged in');

      await createALbum({
        data,
        token: session.token,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['albums'],
      });
    },
  });

  const updateMutation = useMutation({
    mutationFn: async ({ id, data }: { id: Album['id']; data: UpdateAlbumApiRequest }) => {
      if (!session?.token) throw new Error('User is not logged in');

      await updateAlbum({
        id,
        data,
        token: session.token,
      });
    },
    onSuccess: async (_, { id }) => {
      await queryClient.invalidateQueries({
        queryKey: ['album'],
      });
      await queryClient.invalidateQueries({
        queryKey: ['album', id],
      });
    },
  });

  const deleteMutation = useMutation({
    mutationFn: async (id: Album['id']) => {
      if (!session?.token) throw new Error('User is not logged in');

      await deleteAlbum(id, session.token);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['albums'],
      });
    },
  });

  return {
    createMutation,
    updateMutation,
    deleteMutation,
  };
};

export default useAlbumMutation;
