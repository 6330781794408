import { Button } from '@nextui-org/button';
import { Popover, PopoverContent, PopoverTrigger } from '@nextui-org/popover';
import { Link } from '@phosphor-icons/react/dist/ssr';

import { LinkEditorPanel } from '../LinkMenu/LinkEditorPanel';

export interface EditLinkPopoverProps {
  onSetLink: (link: string, openInNewTab?: boolean) => void;
}

export const EditLinkPopover: React.FC<EditLinkPopoverProps> = ({ onSetLink }) => {
  return (
    <Popover>
      <PopoverTrigger>
        <Button isIconOnly radius='none' variant='light'>
          <Link size={20} />
        </Button>
      </PopoverTrigger>

      <PopoverContent className='rounded-none border-1 border-gray-100 bg-background p-2'>
        <LinkEditorPanel onSetLink={onSetLink} />
      </PopoverContent>
    </Popover>
  );
};
