import {
  FacebookLogo,
  Globe,
  InstagramLogo,
  LinkedinLogo,
  XLogo,
} from '@phosphor-icons/react/dist/ssr';

export const SOCIAL_LINK_TYPES = [
  { key: 'website', label: 'Website', icon: <Globe size={24} /> },
  { key: 'x', label: 'X', icon: <XLogo size={24} /> },
  { key: 'linkedin', label: 'LinkedIn', icon: <LinkedinLogo size={24} /> },
  { key: 'instagram', label: 'Instagram', icon: <InstagramLogo size={24} /> },
  { key: 'facebook', label: 'Facebook', icon: <FacebookLogo size={24} /> },
];
