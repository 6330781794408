import { Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';
import { mergeAttributes } from '@tiptap/core';

import ImageGridView from './view/ImageGridView';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    imageGrid: {
      setImageGrid: (attributes: { urls: string[] }) => ReturnType;
    };
  }
}

export const ImageGrid = Node.create({
  name: 'grid',

  group: 'block',

  draggable: true,

  defining: true,

  isolating: true,

  addAttributes() {
    return {
      urls: {
        default: [],
        parseHTML: (element) =>
          Array.from(element.querySelectorAll('img')).map((img) => img.getAttribute('src')),
      },
      'data-type': {
        default: this.name,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `grid[data-type="${this.name}"]`,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    const urls = HTMLAttributes?.urls || [];

    return [
      'grid',
      mergeAttributes(this.options.HTMLAttributes, {
        'data-type': this.name,
        class: 'grid grid-cols-3 gap-4',
      }),
      ...urls.map((url: string) => [
        'img',
        mergeAttributes(this.options.HTMLAttributes, {
          src: url,
          'data-type': this.name,
          class: 'h-full w-full object-cover',
        }),
      ]),
    ];
  },

  addCommands() {
    return {
      setImageGrid:
        (attrs) =>
        ({ commands }) => {
          return commands.insertContent({ type: 'grid', attrs: { urls: attrs.urls } });
        },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(ImageGridView);
  },
});

export default ImageGrid;
