export const humanizeNumber = (num?: number, options?: Intl.NumberFormatOptions) =>
  num !== undefined && !Number.isNaN(num)
    ? Intl.NumberFormat(undefined, {
        notation: 'standard',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
        ...options,
      }).format(num)
    : undefined;

export const formatCurrency = (num?: number) =>
  !Number.isNaN(num) && num !== undefined
    ? new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(num)
    : undefined;

export const random = (min: number, max: number) =>
  Math.floor(Math.random() * (max - min + 1)) + min;
