import { Modal, ModalContent, ModalBody } from '@nextui-org/modal';
import { Input } from '@nextui-org/input';
import { cn } from '@nextui-org/theme';
import { SetStateAction, Dispatch, useState } from 'react';
import { Button } from '@nextui-org/button';
import { Hash } from '@phosphor-icons/react';
import { Checkbox, CheckboxGroup } from '@nextui-org/checkbox';
import { Image } from '@nextui-org/image';
import { Spinner } from '@nextui-org/spinner';

import { body, heading } from '@/theme/typography';
import { getDetailModel } from '@/services/apis/detailModel';
import { Photo } from '@/types/apis/model';

interface GalleryCodeModalProps {
  isOpen: boolean;
  onClose: () => void;
  setConfirmedPhotos: Dispatch<SetStateAction<Photo[]>>;
  onlyAllowSelectOne?: boolean;
}

export function GalleryCodeModal({
  isOpen,
  onClose,
  setConfirmedPhotos,
  onlyAllowSelectOne = false,
}: GalleryCodeModalProps) {
  const [inputValue, setInputValue] = useState<string>('');
  const [galleryCode, setGalleryCode] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [photos, setPhotos] = useState<Photo[]>([]);
  const [model, setModel] = useState<string>('');

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15; // 5 columns × 3 rows
  const [selectedPhotos, setSelectedPhotos] = useState<Photo[]>([]);

  const paginatedPhotos = photos.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage,
  );

  const totalPages = Math.ceil(photos.length / itemsPerPage);

  const [error, setError] = useState<string | null>(null);

  const handleInputChange = (value: string) => {
    setInputValue(value);
    try {
      const url = new URL(value);
      const code = url.pathname.split('/').pop();

      if (code) {
        setGalleryCode(code);
      } else {
        setGalleryCode(value);
      }
    } catch {
      setGalleryCode(value);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      setError(null);
      const response = await getDetailModel({ id: galleryCode });

      if (response?.modelsDetail && response.modelsDetail.length > 0) {
        setPhotos(response.modelsDetail);
        setModel(response.model.name);
        setInputValue('');
        setGalleryCode('');
      } else {
        setError('This code is not found');
        setPhotos([]);
      }
    } catch (error) {
      console.error('Error fetching gallery:', error);
      setError('Code is not found');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectAll = () => {
    if (selectedPhotos.length === photos.length) {
      setSelectedPhotos([]);
    } else {
      setSelectedPhotos(photos);
    }
  };

  const handleImageClick = (photoId: string) => {
    setSelectedPhotos((prev) => {
      const photo = photos.find((p) => p.id.toString() === photoId);

      if (!photo) return prev;

      if (onlyAllowSelectOne) {
        if (prev.some((p) => p.id.toString() === photoId)) {
          return [];
        }

        return [photo];
      }

      if (prev.some((p) => p.id.toString() === photoId)) {
        return prev.filter((p) => p.id.toString() !== photoId);
      }

      return [...prev, photo];
    });
  };

  const handleDone = () => {
    setConfirmedPhotos(selectedPhotos);
    setSelectedPhotos([]);
    onClose();
  };

  // console.log(photos, selectedPhotos, 'check');

  return (
    <Modal
      hideCloseButton
      classNames={{
        body: 'py-6 bg-white-50',
        backdrop: 'bg-[#292f46]/50 backdrop-opacity-40',
        base: 'border-[#292f46] bg-white',
        // header: 'border-b-[1px] border-[#292f46]',
        // footer: 'border-t-[1px] border-[#292f46]',
      }}
      isOpen={isOpen}
      radius='none'
      size='5xl'
      onClose={onClose}
    >
      <ModalContent>
        <ModalBody>
          <div className='flex justify-between border-b border-gray-50 pb-4'>
            <p className={cn(heading({ size: 'h5' }))}>Gallery Code</p>

            <div className='flex gap-2'>
              <Button
                className={cn(body({ weight: 'bold' }), 'w-[7.625rem] border-gray-100')}
                radius='none'
                variant='ghost'
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                className={cn(body({ weight: 'bold' }), 'w-[7.625rem]')}
                color='primary'
                // isLoading={isLoading}
                radius='none'
                onClick={handleDone}
              >
                Done
              </Button>
            </div>
          </div>

          <Input
            isClearable
            className='bg-white mt-1 border border-white-100'
            classNames={{
              input: 'bg-white hover:!bg-white focus:!bg-white',
              inputWrapper: 'bg-white data-[hover=true]:bg-white group-data-[focus=true]:bg-white',
            }}
            endContent={
              <button
                className={cn('cursor-pointer text-primary hover:text-blue-400')}
                onClick={handleSubmit}
              >
                Search
              </button>
            }
            placeholder='Paste or type a album link or album code'
            radius='none'
            startContent={<Hash className='text-gray-800' size={24} />}
            value={inputValue}
            onChange={(e) => handleInputChange(e.target.value)}
            onKeyPress={handleKeyPress}
          />

          {error && <p className={cn('mt-2 text-sm font-medium text-red-500')}>{error}</p>}

          {!photos.length || isLoading ? (
            <div className='flex h-[20.3rem] w-full items-center justify-center'>
              {isLoading ? (
                <Spinner color='primary' />
              ) : (
                <p className={cn('text-white')}>Paste or type a album link or album code</p>
              )}
            </div>
          ) : (
            <div className='mt-4'>
              {photos.length > 0 && (
                <div className='mb-4 flex items-center justify-between'>
                  <div className='flex w-full items-center justify-between gap-2'>
                    <p className={cn(heading({ size: 'h6' }), 'text-gray-800')}>{model}</p>

                    {!onlyAllowSelectOne && (
                      <Checkbox
                        isIndeterminate={
                          selectedPhotos.length > 0 && selectedPhotos.length < photos.length
                        }
                        isSelected={selectedPhotos.length === photos.length}
                        radius='none'
                        onValueChange={handleSelectAll}
                      >
                        <p className={cn('text-sm font-medium')}>
                          {selectedPhotos.length === photos.length ? 'Unselect All' : 'Select All'}
                        </p>
                      </Checkbox>
                    )}
                  </div>
                </div>
              )}

              <CheckboxGroup
                value={selectedPhotos.map((photo) => photo.id.toString())}
                onValueChange={(values: string[]) => {
                  const newSelectedPhotos = values
                    .map((value) => photos.find((photo) => photo.id.toString() === value)!)
                    .filter(Boolean);

                  setSelectedPhotos(newSelectedPhotos);
                }}
              >
                <div className='grid grid-cols-5 gap-4'>
                  {paginatedPhotos.map((photo) => (
                    <button
                      key={photo.id}
                      className='group relative cursor-pointer'
                      onClick={() => handleImageClick(photo.id.toString())}
                    >
                      <div className='absolute left-2 top-2 z-20'>
                        <Checkbox
                          classNames={{
                            base: 'max-w-none',
                            wrapper:
                              "before:bg-gray-800/50 before:content-[''] before:block before:absolute before:-inset-1 hover:before:bg-gray-800",
                          }}
                          radius='none'
                          value={photo.id.toString()}
                        />
                      </div>
                      <Image
                        alt={photo.image.alternativeText || 'Gallery image'}
                        className='z-10 h-[9.9rem] w-full object-cover'
                        radius='none'
                        src={photo.image.url}
                      />
                    </button>
                  ))}
                </div>
              </CheckboxGroup>
            </div>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
