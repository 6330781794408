import { useQuery } from '@tanstack/react-query';

import { getModels, getModelsInCodename } from '@/services/apis/model';

const useModels = ({ params }: any) => {
  const { data, ...others } = useQuery({
    queryKey: ['models', params],
    queryFn: () => getModels({ params }),
    staleTime: 10 * 1000,
    select: (data) => data,
  });

  return {
    models: data,
    ...others,
  };
};

const useModelsInCodeNames = ({ params }: any) => {
  const { data, ...others } = useQuery({
    queryKey: ['models-in-codenames', params],
    queryFn: () => getModelsInCodename({ id: params.id, params }),
    staleTime: 10 * 1000,
    select: (data) => data,
  });

  return {
    models: data,
    ...others,
  };
};

export default useModels;

export { useModelsInCodeNames };
