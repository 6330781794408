import React, { createContext, useCallback, useState } from 'react';

import { ConfirmationModal } from '@/components';
import { ConfirmationModalProps } from '@/components/Modals/ConfirmationModal';

export type ConfirmOptions = Partial<Omit<ConfirmationModalProps, 'isOpen' | 'onClose'>>;

const DEFAULT_OPTIONS = {
  title: 'Are you sure?',
  message: 'This action cannot be undone',
  cancelText: 'Cancel',
  confirmText: 'Confirm',
};

interface ConfirmContextProps {
  confirm: (_options?: ConfirmOptions) => Promise<unknown>;
}

export const ConfirmContext = createContext<ConfirmContextProps>({
  confirm: () => Promise.resolve(),
});

interface IConfirmProviderProps {
  children?: React.ReactNode;
  defaultOptions?: ConfirmOptions;
}

const ConfirmProvider: React.FC<IConfirmProviderProps> = ({
  children,
  defaultOptions = DEFAULT_OPTIONS,
}) => {
  const [options, setOptions] = useState<ConfirmOptions>(defaultOptions);
  const [resolveReject, setResolveReject] = useState<((_value?: any) => void)[]>([]);
  const [resolve, reject] = resolveReject;

  const [isCancelLoading, setIsCancelLoading] = useState(false);
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);

  const confirm = useCallback((options = {}) => {
    return new Promise((resolve, reject) => {
      setOptions(options);
      setResolveReject([resolve, reject]);
    });
  }, []);

  const handleClose = useCallback(() => {
    setResolveReject([]);
  }, []);

  const handleCancel = useCallback(() => {
    setIsCancelLoading(true);
    options.onCancel?.();
    setIsCancelLoading(false);

    if (reject) {
      reject();
      handleClose();
    }
  }, [reject, handleClose]);

  const handleConfirm = useCallback(async () => {
    setIsConfirmLoading(true);
    await options.onConfirm?.();
    setIsConfirmLoading(false);

    if (resolve) {
      resolve();
      handleClose();
    }
  }, [resolve, handleClose, options.onConfirm]);

  const providerValue = React.useMemo(() => ({ confirm }), [confirm]);

  return (
    <>
      <ConfirmContext.Provider value={providerValue}>{children}</ConfirmContext.Provider>

      <ConfirmationModal
        {...DEFAULT_OPTIONS}
        {...options}
        cancelButtonProps={{
          ...options.cancelButtonProps,
          isLoading: isCancelLoading,
          disabled: isConfirmLoading,
        }}
        confirmButtonProps={{
          ...options.confirmButtonProps,
          isLoading: isConfirmLoading,
          disabled: isCancelLoading,
        }}
        isOpen={resolveReject.length === 2}
        onCancel={handleCancel}
        onClose={handleClose}
        onConfirm={handleConfirm}
      />
    </>
  );
};

export default ConfirmProvider;
