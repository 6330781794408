import { useQuery } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';

import { getUserFollowers } from '@/services/apis/user';

const useUserFollowers = (username: string) => {
  const { data: session } = useSession();

  const { data, ...others } = useQuery({
    queryKey: ['followers', username],
    queryFn: () => getUserFollowers(username, session?.token),
    staleTime: 10 * 1000,
    select: (data) => data,
    enabled: !!username,
  });

  return {
    followers: data,
    ...others,
  };
};

export default useUserFollowers;
