import { Button } from '@nextui-org/button';
import { Input } from '@nextui-org/input';
import { Link } from '@phosphor-icons/react/dist/ssr';
import { useState, useCallback, useMemo } from 'react';

export type LinkEditorPanelProps = {
  initialUrl?: string;
  onSetLink: (url: string) => void;
};

export const LinkEditorPanel = ({ onSetLink, initialUrl }: LinkEditorPanelProps) => {
  const [url, setUrl] = useState(initialUrl || '');

  const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setUrl(event.target.value);
  }, []);

  const isValidUrl = useMemo(() => /^(\S+):(\/\/)?\S+$/.test(url), [url]);

  const handleSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      if (isValidUrl) {
        onSetLink(url);
      }
    },
    [url, isValidUrl, onSetLink],
  );

  return (
    <div className='flex'>
      <form className='flex items-center gap-2' onSubmit={handleSubmit}>
        <Input
          className='min-w-[12rem] flex-1 text-sm text-black outline-none'
          placeholder='Enter URL'
          radius='none'
          size='md'
          startContent={<Link size={20} />}
          type='url'
          value={url}
          onChange={onChange}
        />

        <Button color='primary' isDisabled={!isValidUrl} radius='none' size='md' type='submit'>
          Set Link
        </Button>
      </form>
    </div>
  );
};
