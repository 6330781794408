import { BubbleMenu as BaseBubbleMenu } from '@tiptap/react';
import React, { useCallback } from 'react';
import { Button } from '@nextui-org/button';
import { RowsPlusBottom, RowsPlusTop, TrashSimple } from '@phosphor-icons/react';
import { Card, CardBody } from '@nextui-org/card';

import { isRowGripSelected } from './utils';

import { MenuProps, ShouldShowProps } from '@/types/editor/type';

export const TableRowMenu = React.memo(({ editor, appendTo }: MenuProps): JSX.Element => {
  const shouldShow = useCallback(
    ({ view, state, from }: ShouldShowProps) => {
      if (!state || !from) {
        return false;
      }

      return isRowGripSelected({ editor, view, state, from });
    },
    [editor],
  );

  const onAddRowBefore = useCallback(() => {
    editor.chain().focus().addRowBefore().run();
  }, [editor]);

  const onAddRowAfter = useCallback(() => {
    editor.chain().focus().addRowAfter().run();
  }, [editor]);

  const onDeleteRow = useCallback(() => {
    editor.chain().focus().deleteRow().run();
  }, [editor]);

  return (
    <BaseBubbleMenu
      editor={editor}
      pluginKey='tableRowMenu'
      shouldShow={shouldShow}
      tippyOptions={{
        appendTo: () => {
          return appendTo?.current;
        },
        placement: 'left',
        offset: [0, 15],
        popperOptions: {
          modifiers: [{ name: 'flip', enabled: false }],
        },
      }}
      updateDelay={0}
    >
      <Card className='w-48 bg-white-50 shadow-md' radius='none'>
        <CardBody className='p-2'>
          <Button
            fullWidth
            className='mb-2 justify-start'
            radius='none'
            startContent={<RowsPlusTop size={18} />}
            variant='light'
            onClick={onAddRowBefore}
          >
            Add row before
          </Button>
          <Button
            fullWidth
            className='mb-2 justify-start'
            radius='none'
            startContent={<RowsPlusBottom size={18} />}
            variant='light'
            onClick={onAddRowAfter}
          >
            Add row after
          </Button>
          <Button
            fullWidth
            className='justify-start text-danger'
            radius='none'
            startContent={<TrashSimple size={18} />}
            variant='light'
            onClick={onDeleteRow}
          >
            Delete row
          </Button>
        </CardBody>
      </Card>
    </BaseBubbleMenu>
  );
});

TableRowMenu.displayName = 'TableRowMenu';

export default TableRowMenu;
