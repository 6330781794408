import { Button } from '@nextui-org/button';
import { Divider } from '@nextui-org/divider';
import { Tooltip } from '@nextui-org/tooltip';
import { Copy, PencilSimple, Trash } from '@phosphor-icons/react/dist/ssr';

export type LinkPreviewPanelProps = {
  url: string;
  onEdit: () => void;
  onClear: () => void;
};

export const LinkPreviewPanel = ({ onClear, onEdit, url }: LinkPreviewPanelProps) => {
  return (
    <div className='flex items-center gap-2'>
      <a
        className='text-nowrap break-all text-sm underline'
        href={url}
        rel='noopener noreferrer'
        target='_blank'
      >
        {url}
      </a>

      <Divider className='h-5' orientation='vertical' />

      <div>
        <Tooltip classNames={{ content: 'rounded-none px-2 py-1' }} content='Copy link'>
          <Button
            isIconOnly
            radius='none'
            variant='light'
            onClick={() => {
              navigator.clipboard.writeText(url);
            }}
          >
            <Copy size={20} />
          </Button>
        </Tooltip>

        <Tooltip classNames={{ content: 'rounded-none px-2 py-1' }} content='Edit link'>
          <Button isIconOnly radius='none' variant='light' onClick={onEdit}>
            <PencilSimple size={20} />
          </Button>
        </Tooltip>

        <Tooltip classNames={{ content: 'rounded-none px-2 py-1' }} content='Remove link'>
          <Button isIconOnly radius='none' variant='light' onClick={onClear}>
            <Trash size={20} />
          </Button>
        </Tooltip>
      </div>
    </div>
  );
};
