import { create } from 'zustand';

interface TabBarState {
  isTabBarVisible: boolean;
  toggleTabBar: () => void;
  setTabBarVisible: (visible: boolean) => void;
}

const useTabBarStore = create<TabBarState>((set) => ({
  isTabBarVisible: false,
  toggleTabBar: () => set((state) => ({ isTabBarVisible: !state.isTabBarVisible })),
  setTabBarVisible: (visible: boolean) => set({ isTabBarVisible: visible }),
}));

export default useTabBarStore;
