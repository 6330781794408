import { saveAs } from 'file-saver';

export const saveImage = async (imageUrl: string, prefix?: string) => {
  try {
    const res = await fetch(imageUrl);
    const resBlob = await res.blob();

    // Get image name from image URL
    // Remove the file extension
    const name = new URL(imageUrl).pathname.split('/').pop()?.split('.')[0];

    // Determine the file extension based on the MIME type
    const mimeType = resBlob.type;
    let extension = '';

    switch (mimeType) {
      case 'image/jpeg':
        extension = 'jpg';
        break;
      case 'image/png':
        extension = 'png';
        break;
      case 'image/gif':
        extension = 'gif';
        break;
      case 'image/webp':
        extension = 'webp';
        break;
      case 'image/bmp':
        extension = 'bmp';
        break;
      case 'image/svg+xml':
        extension = 'svg';
        break;
      default:
        extension = 'png'; // Default to png if the MIME type is unrecognized
    }

    const fileName = `${prefix ? `${prefix}-` : ''}${name}.${extension}`;

    // Save the image using file-saver
    saveAs(resBlob, fileName);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error saving the image:', error);

    throw new Error('Error saving the image');
  }
};
